export const CreateBillApprovalCardDescription = `
<p>Hi,</p><p> </p><p>Please review the below bills and either</p><p> </p><ul><li>Approve the bill for payment on the planned date.</li><li>Decline the bill</li></ul><p> </p><p>All approved bills will be paid on the planned payment date.</p><p> </p><p>Thanks</p>`;

export enum BillApprovalReviewModalData {
    Title = "Are you sure you want to send this bill approval for review?",
    Subhead = "Changes will be saved and sent to your Visory team for attention.",
}

export enum BillApprovalCompleteModalData {
    Title = "Are you sure you want to approve this bill approval?",
    Subhead = "No further changes can be made and we will process this bill approval.",
}
