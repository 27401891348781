import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-upload-cancel-modal",
    templateUrl: "./upload-cancel-modal.component.html",
    styleUrls: ["./upload-cancel-modal.component.scss"],
})
export class UploadCancelModalComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
