import { Pipe, PipeTransform } from "@angular/core";
import { IWorkflowConfiguration, WorkflowTokenResolver, WorkflowRecurrenceService } from "@visoryplatform/threads";
import { DateTime } from "luxon";
import { Observable, of } from "rxjs";
import { WorkflowService } from "../../threads-ui/services/workflow/workflow.service";
import { map } from "rxjs/operators";
import { RRule } from "rrule";

@Pipe({ name: "nextInstance" })
export class NextInstancePipe implements PipeTransform {
    constructor(private workflowService: WorkflowService) {}

    transform(config: IWorkflowConfiguration): Observable<DateTime | null> {
        if (!config.scheduleData || !config.designId) {
            return of(null);
        }

        const { dueDateStep, frequency } = config.scheduleData;

        if (!frequency) {
            return of(null);
        }

        const rrule = RRule.fromString(frequency);
        return this.workflowService.getDesign(config.designId).pipe(
            map((design) => {
                const { resolvedDesign } = WorkflowTokenResolver.resolveDesignWithTokens(config.workflowTokens, design);
                const nextDates = WorkflowRecurrenceService.getNextStart(rrule, resolvedDesign, dueDateStep, 1);
                return nextDates.find((date) => !!date)?.startDate;
            }),
        );
    }
}
