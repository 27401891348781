<ng-container *ngIf="card$ | async; let card">
    <ng-container *ngIf="userId$ | async; let userId">
        <thread-card
            [title]="!!customCardTitle ? customCardTitle : 'Files'"
            [thread]="thread$ | async"
            [card]="card"
            [replies]="replies$ | async"
            [invertTheme]="userId === card.createdBy"
            [edited]="card.status === cardStatuses.Edited"
            [isInternal]="card.isInternal"
            [edit]="edit$"
            (save)="save($event)"
            [loading]="!state?.groups?.length || !!loader.counter"
        >
            <button
                [analyticsClick]="gaEvents.APP_VIEWINSIGHTS"
                *ngIf="report?.fileId && report?.vaultId && !(role | permissions : 'CreateInsights' | async)"
                class="fx-btn fx-btn--primary-outlined rfi-view-insights"
                [routerLink]="['/insights/report', report.vaultId, report.fileId]"
            >
                <i class="las la-chart-bar"></i>
                VIEW INSIGHTS
            </button>

            <ng-container [ngSwitch]="vaultCardType">
                <div
                    class="rfi-content"
                    [ngClass]="{ 'rfi-content--inverted': userId === card.createdBy }"
                >
                    <div
                        class="attachments"
                        *ngSwitchDefault
                    >
                        <vault-attachments
                            *ngIf="state?.groups"
                            [groups]="state.groups"
                            [disabled]="card?.status === cardStatuses.Disabled"
                            [canSign]="role | permissions : 'SignDocumentsVaultCard' | async"
                            [canCreateReports]="role | permissions : 'CreateInsights' | async"
                            [editable]="(edit$ | async) && !(cardExtension$ | async)"
                            (download)="downloadItem($event)"
                            (preview)="openDocumentPreview($event)"
                            (sign)="signItem($event)"
                            (rename)="renameItem($event.item, $event.name)"
                            (markAsSign)="markDocumentAsSign($event)"
                            (markAsReport)="markDocumentAsReport($event)"
                            (delete)="deleteItem($event)"
                        >
                        </vault-attachments>

                        <ng-container *ngIf="(edit$ | async) && !(cardExtension$ | async)">
                            <file-input
                                [analyticsPrefix]="gaEventsPrefix.DOCUMENT_CARD"
                                (file)="uploadFile(card, $event)"
                            >
                            </file-input>

                            <button
                                (click)="edit$.next(false)"
                                class="fx-btn fx-btn--primary-outlined"
                            >
                                Done
                            </button>
                        </ng-container>
                    </div>
                </div>
            </ng-container>

            <div
                *ngIf="(allowEdit && card.createdBy === userId) || (role | permissions : 'RemoveCard' | async)"
                card-actions
            >
                <fx-context-menu [gutter]="false">
                    <ng-container>
                        <a
                            [analyticsClick]="gaEvents.RFI_CARD_EDIT"
                            (click)="editCard()"
                            >Edit</a
                        >
                        <a
                            [analyticsClick]="gaEvents.RFI_CARD_CANCEL"
                            *ngIf="
                                (vaultCardType === vaultCardTypes.RFI || documentsRequireSignature) &&
                                card?.status !== cardStatuses.Disabled
                            "
                            (click)="cancelCard()"
                            >Cancel</a
                        >
                    </ng-container>

                    <a
                        [analyticsClick]="gaEvents.RFI_CARD_REMOVE"
                        *ngIf="
                            (vaultCardType !== vaultCardTypes.RFI && !documentsRequireSignature) ||
                            (role | permissions : 'RemoveCard')
                        "
                        (click)="removeMessage()"
                    >
                        Delete
                    </a>
                </fx-context-menu>
            </div>
            <div card-loader>
                <loader-icon color="light"></loader-icon>
            </div>
            <div
                class="thread-card-error"
                *ngIf="errorMessage"
            >
                {{ errorMessage }}
            </div>
        </thread-card>
    </ng-container>
</ng-container>
