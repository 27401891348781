import { Appearance, StripeElementsOptions } from "@stripe/stripe-js";

import { CountryCode } from "libphonenumber-js";
import { FrequencyLabel } from "../account/constants/configuration-settings.constants";
import { ICreateRequestMenu } from "projects/default-plugins/vault/types/CreateRequest";
import { IPrice } from "@visoryplatform/payments-service-sdk";
import { ITileModel } from "../findex-ui/components/fx-tile/fx-tile.component";
import { IUserProfileExtension } from "../user-profile/components/user-profile/IUserProfileExtension";
import { ThreadTypes } from "@visoryplatform/threads";
import { Tour } from "../shared/interfaces/tours";
import { Type } from "@angular/core";
import { workflowConfigTokens } from "../workflow-variations/constants/workflow-config-tokens.constants";

export enum CloseAction {
    CreateThread = "create-thread",
    CancelThread = "cancel-thread",
    CancelMeetings = "cancel-meetings",
}

export interface ICalendarMeetingDuration {
    durationMinutes: number;
    description: string;
    defaultSelection?: boolean;
}

export const defaultCalendarMeetingDurations = [
    { durationMinutes: 15, description: "15 mins" },
    { durationMinutes: 30, description: "30 mins", defaultSelection: true },
    { durationMinutes: 45, description: "45 mins" },
    { durationMinutes: 60, description: "60 mins" },
    { durationMinutes: 90, description: "90 mins" },
];

export const taskChannelPrefix = "tasks/threads";

export type MetadataField = { label: string; type?: "chip"; valueLabels?: { [key: string]: string } };

export interface IAccountMetadataConfiguration {
    [name: string]: {
        [name: string]: MetadataField;
    };
}

export type FeatureConfig = {
    signupEnabled: boolean;
    signupCountries: CountryCode[];
    subscriptionEnabled: boolean;
    userProfileExtension?: Type<IUserProfileExtension>;
    mobileVerifiedRedirect: string;
    jobStatusEnabled: boolean;
    closeThreadContextMenu: boolean;
    rfiCategories: string[];
    newThreadButton: boolean;
    calendarMeetingDurations: ICalendarMeetingDuration[];
    tours?: Tour[];
    supportEmail?: string;
    serviceDeskUrl?: string;
    windowWidthMenuBreakpoint: number;
    windowWidthTabletBreakpoint: number;
    focusWizardEnabled: boolean;
    // Temp - enable secondary branding alongside the primary branding. Used for crowe logo
    secondaryBranding?: boolean;
    accountView?: boolean;
    /**
     * changes the name of the purchasable package, e.g service package or subscription package
     */
    packageDisplayName: string;
    initialThreadDetails?: {
        threadType: ThreadTypes;
    };

    requiresOnboarding?: boolean;
    /**
     * We hope to be able to remove these feature flags at some point.
     */
    temporaryFeatureFlags: {
        staffTitleOverride?: string;
        hideThreadTypeMyTasks?: boolean;
    };
    addParticipants: boolean;
    inviteClients: boolean;
    threadParticipantDetail: boolean;
    showAgreePoliciesLogin: boolean;
    selectStaffAnimationLoadTimeRange?: {
        min: number; // millis
        max: number; // millis
    };

    //This will one day be pulled from a resource pack
    text: {
        loginTitle?: string;
        loginDescription?: string;
        loginAction?: string;
        loginFooter?: string;
        signUpTitle?: string;
        signupInstruction?: string;
        signupAction?: string;
        createPasswordTitle?: string;
        createPasswordDescription?: string;
        createPasswordAction?: string;
        verifyEmailTitle?: string;
        verifyEmailDescription?: string;
        verifyMobileTitle?: string;
        verifyMobileDescription?: string;
        verifyMobileAction?: string;
        verifyMobileFooter?: string;
        verifyCodeTitle?: string;
        verifyCodeDescription?: string;
        verifyCodeAction?: string;
        verifyCodeFooter?: string;
        selectStaffMemberDescription?: string;
        matchLoaderDescription?: string;

        default: {
            loginTitle: string;
            loginDescription: string;
            loginAction: string;
            loginFooter: string;
            signUpTitle: string;
            signupInstruction: string;
            signupAction: string;
            createPasswordTitle: string;
            createPasswordDescription: string;
            createPasswordAction: string;
            verifyEmailTitle: string;
            verifyEmailDescription: string;
            verifyMobileTitle: string;
            verifyMobileDescription: string;
            verifyMobileAction: string;
            verifyMobileFooter: string;
            verifyCodeTitle: string;
            verifyCodeDescription?: string;
            verifyCodeAction: string;
            verifyCodeFooter: string;
            notInvitedToMeeting: string;
        };
    };

    /**
     * Allow user to sign file only after downloading the file
     * Defaults to false
     */
    signingRequiresFileDownloadEnabled: boolean;
    editCardDescription?: boolean;

    /**
     * Whether the app supports nested threads / breadcrumb navigation
     * Defaults to false
     */
    threadListFilterStatus: {
        closed?: string;
        cancelled?: string;
        active: string;
    };
    showPaymentsSubscriptions?: boolean;
    showPaymentsBilling?: boolean;
    showPayrollSettings?: boolean;
    enableBrandLoader: boolean;
    updateAccountMetadataOnVerifyMobile?: boolean;
    accountMetadataConfiguration?: IAccountMetadataConfiguration;
    onboardingConfiguration?: {
        selectServices?: {
            title: string;
            subtitle: string;
            tiles: ITileModel[];
        };
        selectIndustry?: {
            title: string;
            subtitle: string;
            tiles: ITileModel[];
        };
        selectGoals?: {
            title: string;
            subtitle: string;
            tiles: ITileModel[];
            mobileImage?: string;
            desktopImage?: string;
        };
        selectNumberOfEmployees?: { title: string; subtitle: string; tiles: ITileModel[] };
    };
    insightsConfiguration?: {
        showInsights?: boolean;
        emptyStateImage: string;
    };
    vaultConfiguration?: {
        showVaultList: true;
    };
    onboardingStaffTitle?: string;
    createRequestConfiguration?: ICreateRequestMenu[];
    hideDashboardThreadsStatusCol?: boolean;
    canViewCalendarPage?: boolean;
    calendarBookingLimits?: {
        maxTimeAheadMs: number;
    };
};

export type EnvironmentSpecificConfig = {
    appVersion: string;
    appName: string;
    aiName: string;
    appTheme: string;
    appId: string;
    appUrl: string;
    featureFlags: FeatureConfig;
    production: boolean;
    enableServiceWorkerRefresh: boolean;
    stage: string;
    sentry: string;
    analytics: {
        google: {
            trackingId?: string;
            tagManagerId?: string;
            tagManagerAuth?: string;
            tagManagerPreview?: string;
        };
    };
    freshmarketer?: {
        cdnUrl: string;
    };
    theme: {
        onboardingCentreAligned: boolean;
        formSubmitActionOutlined: boolean;
    };
    searchOptimization: {
        url: string;
    };
    threadsEndpoints: {
        base: string;
    };
    commonEndpoints: {
        base: string;
    };
    workflowEndpoints: {
        base: string;
    };
    sigmaEndpoints: {
        base: string;
    };
    videoChatEndpoints: {
        base: string;
    };
    paymentsEndpoints: {
        base: string;
    };
    calendarEndpoints: {
        base: string;
    };
    publicEndpoints: {
        base: string;
        cachedBaseSystem: string;
        cachedBaseImages: string;
    };
    payments: {
        publishableApiKey: string;
        taxRateIds: {
            [currency: string]: string;
        };
    };
    vaultEndpoints: {
        base: string;
    };
    vaultThreadsEndpoints: {
        base: string;
    };
    notificationsEndpoints: {
        base: string;
        websockets: string;
    };
    tokensEndpoints?: {
        base: string;
    };
    threadsWebsockets: string;
    registration: {
        redirectUrl: string;
    };
    emailVerifyUrl: string;
    auth: {
        base: string;
        userPoolId: string;
        userPoolWebClientId: string;
        protectedResources: string[];
        unprotectedResources?: string[];
        includeCredentialsEndpoints: string[];
        forgotPasswordRedirect: string;
        authenticationFlowType: "USER_SRP_AUTH" | "USER_PASSWORD_AUTH";
    };
    activeDirectory: {
        clientId: string;
    };
    opentokApiKey: string;
    openMeasures?: {
        endpoint: string;
    };
    policyUrl?: string;
    termsUrl?: string;
    termsOfBusinessUrl?: string;
    disclaimerUrl?: string;
    emailDisclaimerUrl?: string;
    calendarLocation: string;
    signupRedirectUrl?: string;
    errorRedirectUrl: string;
    emailTimelineUrl: string;
    staffSupportUrl?: string;
    searchRoles?: string[];
    uploadFilesizeLimitMB?: number;
    uploadFilesizeLimitErrorMessage?: string;
    textareaCharacterLimit?: number;
    onboardingVideoUrl?: string;
    whitelistedUrls?: string[];
    virtualBackgroundPresets?: { [key: string]: { src: string } };
    videoCallAppUrl?: string;
    launchDarkly: {
        clientSideId: string;
    };
};

export const environmentCommon = {
    threadsEndpoints: {
        threads: "/threads",
        events: "/events",
        reply: "/reply",
        cards: "/cards",
        cardListings: "/card-listings",
        participants: "/participants",
        inviteParticipants: "/threads/:threadId/participants/invite",
        role: "/role",
        permissions: "/permissions",
        data: "/data",
        users: "/users",
        myProfile: "/users/me",
        staff: "/staff",
        tasks: "/tasks",
        state: "/state",
        lastLogin: "/users/:userId/lastLogin",
        deleteUser: "/user/:userId",
        timelines: "/timelines",
        timelineGroups: "/timelines/groups",
        workflow: "/workflow",
        steps: "/steps",
        timeestimates: "/timeestimates",
        contacts: "/contacts",
        accounts: "/accounts",
        migrateCalendarCards: "/migrateCalendarCards",
        notifications: "/notifications",
        cardReadStatus: "/readStatus",
        cardTasks: "/cards/tasks",
        actionLink: "/actionLink/:actionId",
        usersNotificationSettings: "/users/:userId/settings/notifications",
        systemStatus: "/status",
        searchTimelines: "/search/timelines",
        dashboardSearchTimelines: "/search/dashboard-timelines",
        replaceWorkflows: "/replace/workflows",
        replaceConfigurations: "/replace/configurations",
        filters: "/filters",
        forward: "/forward",
        target: "/target",
    },
    workflowEndpoints: {
        designs: "/designs",
        types: "/types",
        workflows: "/workflows",
        workflow: "/workflow",
        extensions: "/extensions",
        data: "/data",
        steps: "/steps",
        actions: "/actions",
        valid: "/valid",
    },
    assistantEndpoints: {
        messages: "/messages",
        assistant: "/assistant",
        chats: "/chats",
        run: "/run",
        retry: "/retry",
        systemPrompt: "/systemPrompt",
    },
    cardsEndpoints: {
        calendar: "calendar",
        bookedCalendar: "calendar/booked",
        videoChat: "videoChat",
        videoCall: "videoCall",
        vault: "vault",
        message: "message",
        payment: "payment",
    },
    sigmaEndpoints: {
        onboarding: "/onboarding",
        signUp: "/signup",
        subscription: "/subscription",
        mySubscription: "/subscription/me",
        packages: "/packages",
        validPackage: "/valid",
        participant: "/participant",
        insightsReports: "/insights/user",
    },
    videoChatEndpoints: {
        generateToken: "generateToken",
        terminateSession: "terminateSession",
        endSession: "endSession",
        createSession: "createSession",
        recordings: "recordings",
        download: "download",
        signature: "signature",
    },
    paymentsEndpoints: {
        prices: "prices",
        payment: "payment",
        subscription: "subscription",
        userDetails: "user",
        account: "account",
        currentUserDetails: "me",
        paymentMethod: "paymentMethod",
        paymentMethodPaymentCard: "paymentMethod/card",
        customer: "customer",
        setDefaultPaymentMethod: "customer/defaultPaymentMethod",
        addPaymentMethod: "addPaymentMethod",
        removePaymentMethod: "removePaymentMethod",
        invoices: "invoices",
        completePayment: "complete",
        activateSubscription: "activate",
        setupIntent: "setup",
    },
    calendarEndpoints: {
        upcomingMeetings: "meetings",
        getInvitation: "getInvitation",
        setAppointment: "setAppointment",
        setAttendees: "setAttendees",
        updateInstance: "updateInstance",
        cancelInstance: "cancelInstance",
        cancelAppointment: "cancelAppointment",
        checkAvailability: "checkAvailability",
        publicCheckStaffAvailability: "checkStaffAvailability",
        attendees: "attendees",
    },
    auth: {
        userIdPrefix: "cognito",
        invitation: "/invitationDetail",
        invitationLogin: "/invitationLogin",
        invitationVerifyCode: "/invitationVerifyCode",
        region: "ap-southeast-2",
        endpoints: {
            verifyMobile: "/verifyMobile",
            confirmMobile: "/confirmMobile",
            confirmEmail: "/confirmEmail",
            checkUser: "/checkUser",
            myProfile: "/myProfile",
            forgotPassword: "/forgotPassword",
            forgotPasswordConfirm: "/forgotPasswordConfirm",
        },
    },
    activeDirectory: {
        redirectPath: "/login",
        userIdPrefix: "azuread",
        tenant: "b48a9eab-75aa-4a5b-9fd7-f39223245c1a",
    },
    login: {
        staff: "/staff-login",
    },
    searchRoles: {
        client: "Client",
        staff: "Staff",
    },
    calendarFilterSelectAllOptions: {
        allAccounts: "selectAllAccounts",
        allServices: "selectAllServices",
    },
    defaultTimezone: "Australia/Melbourne",
    uploadFilesizeLimitMB: 30,
    uploadFilesizeIsTooLargeMessage: "Sorry some files you tried to upload are too large:",
    uploadMaxFilesizeMessage: "(Max file size 30mb).",
    filesDidNotUploadErrorMessage: "Sorry upload failed for the following files:",
    pleaseReattemptUploadMessage: "Please reattempt your upload using the 'Browse' button.",
    paymentElementStyle: {
        base: {
            fontSize: "16px",
            lineHeight: "18px",
            fontFamily: "Lato",
        },
    },
    paymentsTailoredSubscriptions: {
        prices: [
            {
                id: "tailoredSubscriptionPrice",
                object: "price",
                active: true,
                billing_scheme: "per_unit",
                created: 1598915581,
                currency: "aud",
                livemode: false,
                lookup_key: null,
                metadata: {},
                nickname: "Custom Tailored Subscription",
                product: "tailoredSubscriptionProduct",
                recurring: {
                    aggregate_usage: null,
                    interval: "month",
                    interval_count: 1,
                    trial_period_days: null,
                    usage_type: "licensed",
                },
                tiers_mode: null,
                transform_quantity: null,
                type: "recurring",
                unit_amount: 0,
                unit_amount_decimal: "0",
            } as IPrice,
            {
                id: "tailoredSubscriptionPrice",
                object: "price",
                active: true,
                billing_scheme: "per_unit",
                created: 1598915581,
                currency: "nzd",
                livemode: false,
                lookup_key: null,
                metadata: {},
                nickname: "Custom Tailored Subscription",
                product: "tailoredSubscriptionProduct",
                recurring: {
                    aggregate_usage: null,
                    interval: "month",
                    interval_count: 1,
                    trial_period_days: null,
                    usage_type: "licensed",
                },
                tiers_mode: null,
                transform_quantity: null,
                type: "recurring",
                unit_amount: 0,
                unit_amount_decimal: "0",
            } as IPrice,
        ],
        tailoredSubscriptionId: "tailoredSubscriptionPrice",
    },
    workflow: {
        states: {
            closed: "closed",
            cancelled: "cancelled",
        },
    },
    textareaCharacterLimit: 5000,
    acceptedCountries: [
        { name: "Australia", countryCode: "AU" },
        { name: "New Zealand", countryCode: "NZ" },
        { name: "Singapore", countryCode: "SG" },
        { name: "United States", countryCode: "US" },
    ],
    quillConfig: {
        toolbarState: {
            withToolbar: {
                toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["clean"],
                ],
                imageCompress: {
                    quality: 0.8,
                    maxHeight: 2000,
                    imageType: "image/jpeg",
                    debug: false,
                    suppressErrorLogging: false,
                    insertIntoEditor: undefined,
                },
            },
        },
        styling: {
            border: "unset",
            "font-size": "16px",
        },
        maxHeight: {
            card: 520, //px
            reply: 300, //px
        },
    },
    dateFormats: {
        short: "h:mm a",
        long: "EEE d MMM, h:mm a",
    },
    focusWizard: {
        path: "perform-action",
    },
    stripeConfig: {
        appearance: {
            theme: "stripe",
            labels: "floating",
            variables: {
                colorPrimary: "#df0074",
                colorBackgroundText: "#6B7683",
                fontFamily: "'Lato', sans-serif",
                fontSizeBase: "1rem",
                spacingGridColumn: "1.5rem",
                spacingTab: "0.75rem",
                colorIconTabSelected: "#ffffff",
            },
            rules: {
                ".Input": {
                    border: "1px solid #6B7683",
                },
                ".Tab": {
                    border: "1px solid #6B7683",
                },
                ".TabLabel": {
                    fontWeight: "400",
                },
                ".Tab--selected": {
                    color: "#ffffff",
                    backgroundColor: "#df0074",
                },
                ".Tab--selected:hover": {
                    color: "#ffffff",
                },
            },
        } as Appearance,
        options: {
            locale: "en-AU",
        } as StripeElementsOptions,
        paymentMethods: {
            aud: ["card", "au_becs_debit"],
            nzd: ["card"],
            sgd: ["card"],
            usd: ["card"],
        },
    },
    staffSupportUrl: "https://findex-digital.atlassian.net/servicedesk/customer/portal/4",
    abaLabels: (appName: string) => ({
        staffUploadsAba: {
            label: `${appName} uploads ABA`,
            description: `Your ${appName} team has access to your bank to upload ABA files.`,
        },
        customerUploadsAba: {
            label: "Customer uploads ABA",
            description: "You upload the ABA file provided by the Visory team.",
        },
        noAba: { label: "no ABA", description: "Direct debit is set up." },
    }),
    labelMaps: {
        [FrequencyLabel.weekly]: "Weekly",
        [FrequencyLabel.fortnightly]: "Fortnightly",
        [FrequencyLabel.monthly]: "Monthly",
    },
    workflowConfigTokens: workflowConfigTokens,
};

export type Environment = typeof environmentCommon & EnvironmentSpecificConfig;
