import {
    Account,
    IWorkflowConfiguration,
    IWorkflowDesignType,
    IntegrationTypes,
    SortOption,
} from "@visoryplatform/threads";
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core";
import { FeatureFlagService, ILaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags";
import { Observable, Subscription, combineLatest, forkJoin, from, of } from "rxjs";
import { SelectDesignControl, SelectServiceControl, SelectWorkflowTokenControl } from "../../types/SelectDesignType";
import { catchError, distinctUntilChanged, map, mergeMap, shareReplay, switchMap, toArray } from "rxjs/operators";

import { AccountsService } from "../../../../services/accounts.service";
import { DesignTypeService } from "../../../../services/workflow/design-type.service";
import { FormControl } from "@angular/forms";
import { IWorkflowDesign } from "@visoryplatform/workflow-core";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { WorkflowConfigurationOptions } from "../../types/UniqueThreadType";
import { WorkflowConfigurationService } from "projects/portal-modules/src/lib/account/services/workflow-configuration.service";
import { WorkflowService } from "../../../../services/workflow/workflow.service";

//This component should be split out into various sub components to manage configurations and designs separately
//Given timing, not going to do that now. Refactored to remove the expression changed error
@Component({
    selector: "select-workflow",
    templateUrl: "./select-workflow.component.html",
    styleUrls: ["./select-workflow.component.scss"],
})
export class SelectWorkflowComponent implements OnChanges, OnDestroy {
    @Input() workflowDesignTypes: IWorkflowDesignType[];
    @Input() filteredDesignTypes: IWorkflowDesignType[];
    @Input() threadType: string;
    @Input() workflowDesignId: string;
    @Input() workflowConfigurationId: string;
    @Input() account: Account;
    @Input() showWorkflowConfigurationSelectors = true;
    @Output() selectedService = new EventEmitter<SelectServiceControl>();
    @Output() selectedDesign = new EventEmitter<SelectDesignControl>();
    @Output() selectedWorkflowTokens = new EventEmitter<SelectWorkflowTokenControl>();

    public sortOption = SortOption;

    workflowDesigns$: Observable<IWorkflowDesign[]>;
    configurations$: Observable<IWorkflowConfiguration[]>;

    serviceControl = new FormControl<SelectServiceControl>(null);
    workflowDesignControl = new FormControl<SelectDesignControl>(null);
    workflowTokenControl = new FormControl<SelectWorkflowTokenControl>(null);
    serviceChanges$: Observable<SelectServiceControl>;
    connectedIntegrations: IntegrationTypes[];

    private designSub?: Subscription;
    private serviceSub?: Subscription;
    private tokenSub?: Subscription;

    constructor(
        public loader: Loader,
        private workflowService: WorkflowService,
        private workflowConfigurationService: WorkflowConfigurationService,
        private designTypeService: DesignTypeService,
        private accountService: AccountsService,
        private featureFlagService: FeatureFlagService,
    ) {
        const controlChanges$ = this.workflowDesignControl.valueChanges.pipe(shareReplay(1));
        this.serviceChanges$ = this.serviceControl.valueChanges.pipe(shareReplay(1));
        const tokenChanges$ = this.workflowTokenControl.valueChanges.pipe(shareReplay(1));

        this.serviceSub = this.serviceChanges$
            .pipe(distinctUntilChanged((a, b) => a.threadType === b.threadType && a.configuration === b.configuration))
            .subscribe((service) => {
                this.workflowDesignControl.setValue({
                    workflowConfigurationId: undefined,
                    designId: undefined,
                });
                this.selectedService.emit(service);
            });

        this.tokenSub = combineLatest([controlChanges$, tokenChanges$]).subscribe(([_, token]) => {
            this.selectedWorkflowTokens.emit(token);
        });
    }

    ngOnDestroy(): void {
        this.designSub?.unsubscribe();
        this.serviceSub?.unsubscribe();
        this.tokenSub?.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const {
            threadType,
            workflowDesignId,
            account,
            workflowConfigurationId,
            workflowDesignTypes,
            showWorkflowConfigurationSelectors,
            filteredDesignTypes,
        } = changes;
        if ((account || workflowDesignTypes) && this.account && this.workflowDesignTypes) {
            this.connectedIntegrations = this.accountService.getConnectedIntegrations(account.currentValue);
            this.setWorkflowConfigurations(this.account, this.workflowDesignTypes);
        }

        if (filteredDesignTypes || workflowDesignTypes) {
            this.workflowDesigns$ = this.serviceChanges$.pipe(
                switchMap((service) =>
                    this.getWorkflowDesignChanges(this.filteredDesignTypes || this.workflowDesignTypes, service),
                ),
                shareReplay(1),
            );
        }

        if (threadType) {
            this.serviceControl.setValue({ ...this.serviceControl.value, threadType: this.threadType });
        }

        if (showWorkflowConfigurationSelectors && showWorkflowConfigurationSelectors.currentValue === false) {
            this.serviceControl.setValue({
                ...this.serviceControl.value,
                configuration: WorkflowConfigurationOptions.Blank,
            });
        }

        if (threadType || workflowDesignId || workflowConfigurationId) {
            this.updateFormValue(this.threadType, this.workflowConfigurationId, this.workflowDesignId);
        }

        if (workflowConfigurationId || workflowDesignId) {
            this.workflowTokenControl.setValue({
                workflowTokenIds: undefined,
            });
        }
    }

    private setWorkflowConfigurations(account: Account, workflowDesignTypes: IWorkflowDesignType[]): void {
        this.configurations$ = this.serviceChanges$.pipe(
            switchMap((value) => this.getWorkflowConfigurationChanges(account, value, workflowDesignTypes)),
        );

        const controlChanges$ = this.workflowDesignControl.valueChanges.pipe(shareReplay(1));
        this.designSub = combineLatest([controlChanges$, this.configurations$]).subscribe(
            ([design, configurations]) => {
                if (design?.workflowConfigurationId) {
                    this.emitWorkflowConfiguration(design, configurations);
                } else {
                    this.selectedDesign.emit(design);
                }
            },
        );
    }

    private emitWorkflowConfiguration(design: SelectDesignControl, configurations: IWorkflowConfiguration[]): void {
        if (!configurations?.length) {
            return;
        }

        const configuration = configurations?.find((config) => config.id === design.workflowConfigurationId);
        const designId = configuration?.designId;
        this.selectedDesign.next({ ...design, designId });
    }

    private getWorkflowConfigurationChanges(
        account: Account,
        service: SelectServiceControl,
        workflowDesignTypes: IWorkflowDesignType[],
    ): Observable<IWorkflowConfiguration[] | null> {
        const configurations$ = this.loader.wrap(this.workflowConfigurationService.listConfigurations(account.id));
        const featureFlags$ = this.featureFlagService.getFlags();

        const workflowConfigurations$ = combineLatest([configurations$, featureFlags$]).pipe(
            switchMap(([response, featureFlags]) =>
                this.getConnectedConfigurationList(
                    response.result,
                    workflowDesignTypes,
                    account,
                    service?.threadType,
                    featureFlags,
                ),
            ),
            shareReplay(1),
        );

        if (service?.configuration === WorkflowConfigurationOptions.Configured) {
            return workflowConfigurations$;
        } else {
            return of(null);
        }
    }

    private getConnectedConfigurationList(
        configurations: IWorkflowConfiguration[],
        designTypes: IWorkflowDesignType[],
        account: Account,
        threadType: string,
        featureFlags: ILaunchDarklyFeatureFlags,
    ): Observable<IWorkflowConfiguration[]> {
        return this.getDesignsFromConfigurations(configurations).pipe(
            map((designs) => {
                const connected = configurations.map((configuration) =>
                    this.getConnectedConfiguration(
                        configuration,
                        designs,
                        designTypes,
                        account,
                        threadType,
                        featureFlags,
                    ),
                );
                return connected;
            }),
            map((configurations) => configurations.filter((configuration) => !!configuration)),
        );
    }

    private getConnectedConfiguration(
        configuration: IWorkflowConfiguration,
        designs: IWorkflowDesign[],
        designTypes: IWorkflowDesignType[],
        account: Account,
        threadType: string,
        featureFlags: ILaunchDarklyFeatureFlags,
    ): IWorkflowConfiguration | null {
        const design = designs.find((design) => design?.id === configuration.designId);

        if (!design) {
            console.error("Workflow configuration is referencing an outdated design id: ", configuration.designId);
            return null;
        }

        const designType = designTypes.find((designType) => designType.workflowDesignId === design?.id);
        const connectedIntegrations = this.accountService.getConnectedIntegrations(account);

        const filteredDesignTypes = this.designTypeService.filterDesignTypes(
            designType ? [designType] : [],
            threadType,
            connectedIntegrations,
            featureFlags,
        );

        return filteredDesignTypes?.length ? configuration : null;
    }

    private getDesignsFromConfigurations(configurations: IWorkflowConfiguration[]): Observable<IWorkflowDesign[]> {
        const designIds = [...new Set(configurations.map((configuration) => configuration.designId))];

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return from(designIds).pipe(
            mergeMap((designId) => this.workflowService.getDesign(designId).pipe(catchError(() => of(null)))),
            toArray(),
        );
    }

    private getWorkflowDesignChanges(
        workflowDesignTypes: IWorkflowDesignType[],
        value: Partial<SelectServiceControl>,
    ): Observable<IWorkflowDesign[] | null> {
        if (value?.configuration === WorkflowConfigurationOptions.Blank) {
            return this.getWorkflowDesigns(value.threadType, workflowDesignTypes);
        } else {
            return of(null);
        }
    }

    private updateFormValue(threadType: string, workflowConfigurationId: string, workflowDesignId: string): void {
        if (workflowConfigurationId) {
            const setConfigurationOption = this.getConfigurationOption(workflowConfigurationId, workflowDesignId);

            this.serviceControl.patchValue({
                threadType: threadType,
                configuration: setConfigurationOption || this.serviceControl.value?.configuration,
            });

            this.workflowDesignControl.patchValue({
                workflowConfigurationId: workflowConfigurationId,
                designId: workflowDesignId,
            });
        } else {
            this.serviceControl.setValue({
                threadType: threadType,
                configuration: WorkflowConfigurationOptions.Blank,
            });

            this.workflowDesignControl.patchValue({
                designId: workflowDesignId,
            });
        }
    }

    private getConfigurationOption(
        workflowConfigurationId: string,
        workflowDesignId: string,
    ): WorkflowConfigurationOptions {
        if (workflowConfigurationId) {
            return WorkflowConfigurationOptions.Configured;
        } else if (workflowDesignId || !this.showWorkflowConfigurationSelectors) {
            return WorkflowConfigurationOptions.Blank;
        }

        return null;
    }

    private getWorkflowDesigns(threadType: string, designTypes: IWorkflowDesignType[]): Observable<IWorkflowDesign[]> {
        const featureFlags$ = this.featureFlagService.getFlags();

        return featureFlags$.pipe(
            map((flags) =>
                this.designTypeService.filterDesignTypes(designTypes, threadType, this.connectedIntegrations, flags),
            ),
            switchMap((designTypes) => {
                if (!designTypes?.length) {
                    return of([]);
                }

                const designs$ = forkJoin(
                    designTypes?.map((designType) => this.workflowService.getDesign(designType.workflowDesignId)),
                );
                return this.loader.wrap(designs$);
            }),
        );
    }
}
