<div class="vaults">
    <div class="vaults-header">
        <h2>Files</h2>
    </div>

    <div class="vaults-filter">
        <div class="vaults-search-container">
            <app-thread-search
                [analyticsClickEvent]="GA_EVENTS.VAULT_LIST_SEARCHSUBMIT"
                [isMobileView]="false"
                (search)="updateSearchTerm($event)"
                [placeholder]="!isMobileView ? 'Search by filename, service type, workflow, or person' : 'Search files'"
            ></app-thread-search>
        </div>

        <div class="vaults-filter-dropdown">
            <form [formGroup]="form">
                <mat-form-field
                    class="fx-form-field"
                    appearance="outline"
                    floatLabel="never"
                >
                    <mat-select
                        formControlName="dateRange"
                        placeholder="Select range"
                        [disableOptionCentering]="true"
                        panelClass="mat-select-panel"
                    >
                        <mat-option [value]="''">All time</mat-option>

                        <mat-option
                            *ngFor="let time of TIME_RANGE"
                            [value]="time.value"
                        >
                            {{ time.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field
                    class="fx-form-field"
                    appearance="outline"
                    floatLabel="never"
                >
                    <mat-select
                        formControlName="accounts"
                        placeholder="Select accounts"
                        multiple
                        [disableOptionCentering]="true"
                        panelClass="mat-select-panel"
                    >
                        <mat-select-trigger>
                            <span>
                                {{
                                    allAccountsSelected?.selected
                                        ? form?.controls?.accounts?.value?.length - 1
                                        : form?.controls?.accounts?.value?.length
                                }}
                            </span>
                            <span>{{ form?.controls?.accounts?.value?.length > 1 ? " Accounts" : " Account" }}</span>
                        </mat-select-trigger>

                        <mat-option
                            #allAccountsSelected
                            [value]="calendarFilterSelectAlOptions.allAccounts"
                            (click)="toggleSelectAll()"
                            >All accounts</mat-option
                        >

                        <mat-option
                            *ngFor="let account of accounts; trackBy: 'id' | trackProperty"
                            [value]="account.id"
                            (click)="toggleOneItem()"
                        >
                            {{ account.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
        </div>
    </div>

    <app-vault-list-table [documents]="filteredResults"></app-vault-list-table>
</div>
