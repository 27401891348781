<div class="fx-form fx-form--dark">
    <div class="fx-form-group">
        <div class="data-input-field fx-form-control">
            <i class="las la-pen"></i>
            <loader-icon
                class="small-loader"
                *ngIf="(defaultValue$ | async) === null"
                color="light"
            >
            </loader-icon>
            <textarea
                *ngIf="(defaultValue$ | async) !== null"
                class="fx-form-input"
                type="text"
                placeholder="Please enter your text information here"
                aria-label="Name"
                [defaultValue]="(defaultValue$ | async) || ''"
                textareaAutoresize
                fxKeyPressDebounce
                #input
                (debounceKeyPress)="outputTextFile(input.value)"
            ></textarea>
        </div>
    </div>
</div>
<div
    class="data-input-error"
    *ngIf="errorMessage"
>
    <div class="data-input-error-message">
        {{ errorMessage }}
    </div>
</div>
