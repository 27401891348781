<li class="todo-item">
    <fx-checkbox
        class="todo-item-green-checkbox"
        [checked]="completed"
        (change)="trackStatusChanges($event)"
        [disabled]="disabled"
    >
    </fx-checkbox>
    <div
        [ngClass]="{ 'todo-item--checked': completed }"
        class="todo-item-content"
    >
        <ng-content></ng-content>

        <button
            *ngIf="shouldShowDelete"
            (click)="deleteItem.emit()"
            class="todo-item-content-remove"
        >
            <i class="las la-times icon-size"></i>
        </button>
    </div>
</li>
