import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef,
} from "@angular/material/legacy-dialog";
import {
    Account,
    ConfigurationOption,
    IParticipant,
    IThreadListing,
    ITimeline,
    IWorkflowConfiguration,
    IWorkflowDesignType,
    IWorkflowToken,
    Role,
    TitleTemplateService,
    WorkflowConfigTokens,
    WorkflowExtensionService,
    WorkflowTokenResolver,
} from "@visoryplatform/threads";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { WorkflowService } from "../../../../services/workflow/workflow.service";
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from "rxjs";
import { IStep, IWorkflowDesign, IWorkflowInputs, SLA_EXTENSION_TYPE } from "@visoryplatform/workflow-core";
import { catchError, filter, map, shareReplay, startWith, switchMap, take } from "rxjs/operators";
import { ThreadsService } from "../../../../services/threads.service";
import { SelectDesignControl, SelectServiceControl, SelectWorkflowTokenControl } from "../../types/SelectDesignType";
import { DateTime } from "luxon";
import { IParticipantUi } from "../../../../interfaces/IParticipantUi";
import { WorkflowConfigurationService } from "projects/portal-modules/src/lib/account/services/workflow-configuration.service";
import { ParticipantService } from "../../../../services/participant.service";
import { Router } from "@angular/router";
import { WorkflowVariationsService } from "../../../../../workflow-variations/services/workflow-variations.service";
import { environmentCommon } from "../../../../../../lib/environment/environment.common";

export interface CreateWorkflowModalData {
    role?: Role;
    defaultParticipants?: IParticipant[];
    defaultThreadType?: string;
    defaultWorkflowDesignId?: string;
    account: Account;
    defaultThread?: IThreadListing | ITimeline;
}

@Component({
    selector: "create-thread-modal",
    templateUrl: "./create-thread-modal.component.html",
    styleUrls: ["./create-thread-modal.component.scss"],
    providers: [{ provide: Loader, useValue: new Loader() }],
})
export class CreateThreadModalComponent implements OnInit, OnDestroy {
    account?: Account;
    participants$: Observable<IParticipantUi[]>;
    defaultThread: ITimeline | IThreadListing;
    defaultParticipants: IParticipant[];
    defaultWorkflow?: ITimeline;
    dueDates?: Record<string, DateTime>;
    assignees?: Record<string, string[]>;
    selectedHasSlas?: boolean;
    threadType?: string;
    role?: Role;
    workflowConfigurationId?: string;
    workflowDesignId?: string;
    isFormValid: boolean;
    isWorkflowValid = true;
    isFormCompleted = false;

    selectedServiceControl: SelectServiceControl;
    selectedDesignControl: SelectDesignControl;

    workflowDesignTypes$: Observable<IWorkflowDesignType[]>;
    workflowConfiguration$: Observable<IWorkflowConfiguration>;
    filteredWorkflowDesignTypes$: Observable<IWorkflowDesignType[]>;

    customTitle$: BehaviorSubject<string> = new BehaviorSubject("");
    previewTitle$: BehaviorSubject<string> = new BehaviorSubject("");
    selectedDesign$: BehaviorSubject<IWorkflowDesign> = new BehaviorSubject(null);
    selectedDesignType$: BehaviorSubject<IWorkflowDesignType> = new BehaviorSubject(null);
    selectWorkflowTokenControl$: BehaviorSubject<SelectWorkflowTokenControl> = new BehaviorSubject(null);
    workflowDesign$: Observable<IWorkflowDesign>;

    formSub: Subscription;
    configTokenSub: Subscription;

    readonly workflowTokenKeys: WorkflowConfigTokens = environmentCommon.workflowConfigTokens;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: CreateWorkflowModalData,
        public loader: Loader,
        private dialogRef: MatDialogRef<CreateThreadModalComponent>,
        private workflowService: WorkflowService,
        private threadsService: ThreadsService,
        private participantService: ParticipantService,
        private workflowConfigurationService: WorkflowConfigurationService,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private workflowVariationsService: WorkflowVariationsService,
    ) {
        this.account = this.data.account;
        this.defaultThread = this.data.defaultThread;
        this.role = this.data.role;

        this.workflowDesignTypes$ = this.loader.wrap(this.workflowService.listDesignTypes());
        this.filteredWorkflowDesignTypes$ = this.workflowDesignTypes$.pipe(
            map((designTypes) => designTypes?.filter((designType) => this.filterDesignType(designType))),
        );

        if (this.defaultThread?.workflowConfigurationId) {
            this.workflowConfiguration$ = this.getWorkflowConfiguration(this.defaultThread.workflowConfigurationId);
            this.setParticipantUpdates(this.workflowConfiguration$, this.assignees, this.defaultThread);
            this.setIsWorkflowValid(this.workflowConfiguration$);
        }

        this.workflowDesign$ = combineLatest([this.selectedDesign$, this.selectWorkflowTokenControl$]).pipe(
            map(([design, token]) => {
                if (design && token?.workflowTokenIds?.length) {
                    const workflowTokens = this.workflowVariationsService.getWorkflowTokens(token.workflowTokenIds);
                    const { resolvedDesign } = WorkflowTokenResolver.resolveDesignWithTokens(workflowTokens, design);
                    return resolvedDesign;
                }

                return design;
            }),
        );
    }

    ngOnInit(): void {
        this.formSub = this.subscribeToFormChanges();
    }

    ngOnDestroy(): void {
        this.formSub?.unsubscribe();
        this.configTokenSub?.unsubscribe();
    }

    setCustomTitle(
        selectedDesign: IWorkflowDesign,
        selectedDesignType: IWorkflowDesignType,
        customTitle?: string,
    ): void {
        this.customTitle$.next(customTitle);

        if (selectedDesignType.titleTemplate && customTitle?.length) {
            const generatedTitle = this.getTitleFromTemplate(
                selectedDesign,
                this.account,
                customTitle,
                selectedDesignType.titleTemplate,
            );
            this.previewTitle$.next(generatedTitle.trim());
        } else {
            this.previewTitle$.next(customTitle.trim());
        }
    }

    async designSelected(
        selectedService: SelectServiceControl,
        selectedDesign: SelectDesignControl,
        designTypes: IWorkflowDesignType[],
    ): Promise<void> {
        if (!selectedService && !selectedDesign) {
            return;
        }

        this.selectedServiceControl = selectedService;
        this.selectedDesignControl = selectedDesign;

        this.threadType = selectedService?.threadType;
        this.workflowDesignId = selectedDesign?.designId;
        this.workflowConfigurationId = selectedDesign?.workflowConfigurationId;

        if (!selectedService?.threadType || !selectedService?.configuration || !selectedDesign?.designId) {
            this.selectedDesign$.next(null);
            this.changeDetectorRef.detectChanges();
            return;
        }

        if (this.defaultThread?.workflow.designId !== selectedDesign?.designId) {
            this.defaultThread = null;
            this.changeDetectorRef.detectChanges();
        }

        const design = await this.loader.wrap(this.workflowService.getDesign(selectedDesign.designId)).toPromise();

        const designType = designTypes.find(
            (designType) =>
                designType.threadType === selectedService.threadType &&
                designType.workflowDesignId === selectedDesign.designId,
        );

        this.selectedDesign$.next(design);
        this.selectedDesignType$.next(designType);

        this.workflowConfiguration$ = this.getWorkflowConfiguration(this.workflowConfigurationId);
        this.setParticipantUpdates(this.workflowConfiguration$, this.assignees, this.data.defaultThread);
        this.setIsWorkflowValid(this.workflowConfiguration$);
        this.setWorkflowConfigTokens();

        if (!design) {
            console.warn("No design found");
            return;
        }

        this.selectedHasSlas = Object.values(design.steps).some((step: IStep) =>
            step.extensions.some((extension) => extension?.type === SLA_EXTENSION_TYPE),
        );

        this.manageThreadTitle(design, designType);
    }

    createThread(
        threadTitle: BehaviorSubject<string>,
        accountId: string,
        participants$: Observable<IParticipant[]>,
        dueDates: Record<string, DateTime>,
        assignees: Record<string, string[]>,
        selectedDesign$: BehaviorSubject<IWorkflowDesign>,
        selectedDesignType$: BehaviorSubject<IWorkflowDesignType>,
        selectedWorkflowTokens$: BehaviorSubject<SelectWorkflowTokenControl>,
        workflowConfiguration$: Observable<IWorkflowConfiguration>,
    ): void {
        const createThread$ = combineLatest([
            threadTitle,
            workflowConfiguration$,
            participants$,
            selectedDesign$,
            selectedDesignType$,
            selectedWorkflowTokens$,
        ]);
        createThread$
            .pipe(
                filter(([, , participants]) => !!participants?.length),
                take(1),
                switchMap(
                    ([
                        threadTitle,
                        workflowConfig,
                        participants,
                        selectedDesign,
                        selectedDesignType,
                        selectedWorkflowTokens,
                    ]) => {
                        const workflowTokens = this.workflowVariationsService.getWorkflowTokens(
                            selectedWorkflowTokens.workflowTokenIds,
                        );
                        const workflowInputs = this.getWorkflowInputs(
                            selectedDesign,
                            workflowConfig,
                            dueDates,
                            assignees,
                            workflowTokens,
                        );
                        const createThread$ = this.threadsService.createThread(
                            threadTitle,
                            selectedDesign.id,
                            accountId,
                            participants,
                            selectedDesignType.threadType,
                            workflowInputs,
                            workflowConfig?.id,
                            workflowTokens,
                        );
                        return this.loader.wrap(createThread$);
                    },
                ),
            )
            .subscribe((thread) => {
                this.dialogRef.close(thread);
            });
    }

    close(): void {
        this.dialogRef.close();
    }

    async navigateToConfiguration(accountId: string, workflowConfigurationId: string): Promise<void> {
        this.close();
        await this.router.navigate(["/accounts", accountId, "workflow-configurations", workflowConfigurationId]);
    }

    setAssignees(assignees: Record<string, string[]>): void {
        this.assignees = assignees;
        this.setParticipantUpdates(this.workflowConfiguration$, this.assignees, this.data.defaultThread);
    }

    updateParticipants(participants: IParticipantUi[]): void {
        this.participants$ = of(participants);
    }

    workflowTokenSelected(selectWorkflowTokenControl: SelectWorkflowTokenControl): void {
        this.selectWorkflowTokenControl$.next(selectWorkflowTokenControl);
    }

    private getExtensionInputUpdates(
        selectedDesign: IWorkflowDesign,
        workflowConfig: IWorkflowConfiguration,
        dueDates: Record<string, DateTime>,
        assignees: Record<string, string[]>,
        workflowTokensInputs: IWorkflowInputs,
    ): IWorkflowInputs {
        if (workflowConfig?.id) {
            const workflowAssignees = WorkflowExtensionService.getStepAssigneesFromConfig(workflowConfig);
            return WorkflowExtensionService.getExtensionInputUpdates(
                selectedDesign,
                workflowAssignees,
                dueDates,
                workflowTokensInputs,
            );
        } else {
            return WorkflowExtensionService.getExtensionInputUpdates(
                selectedDesign,
                assignees,
                dueDates,
                workflowTokensInputs,
            );
        }
    }

    private getTitleFromTemplate(
        design: IWorkflowDesign,
        account: Account,
        customThreadTitle: string,
        titleTemplate?: string,
    ): string {
        return TitleTemplateService.getThreadTitle(design, account, customThreadTitle, titleTemplate);
    }

    private filterDesignType(designType: IWorkflowDesignType): boolean {
        const hasConfigurations =
            designType.requiresConfiguration === ConfigurationOption.Enabled ||
            designType.requiresConfiguration === ConfigurationOption.Optional;
        const allowAdhocCreation = designType.allowAdhocCreation;

        return hasConfigurations && allowAdhocCreation;
    }

    private getWorkflowConfiguration(workflowConfigurationId?: string): Observable<IWorkflowConfiguration | null> {
        if (!workflowConfigurationId) {
            return of(null);
        }

        return this.loader
            .wrap(this.workflowConfigurationService.getWorkflowConfiguration(this.account.id, workflowConfigurationId))
            .pipe(
                catchError(() => {
                    console.error(`Error fetching workflow configuration with id: ${workflowConfigurationId}`);
                    this.workflowConfigurationId = null;
                    this.defaultThread.workflowConfigurationId = null;
                    return of(null);
                }),
                shareReplay(1),
            );
    }

    private setParticipantUpdates(
        workflowConfig$: Observable<IWorkflowConfiguration>,
        assignees: Record<string, string[]>,
        defaultThread: ITimeline | IThreadListing,
    ): void {
        this.participants$ = workflowConfig$.pipe(
            switchMap((workflowConfig) => {
                if (workflowConfig) {
                    const steps = workflowConfig.steps;
                    const observers = workflowConfig.observers;
                    const workflowAssignees = this.participantService.getWorkflowConfigurationAssignees(steps);
                    const workflowObservers = this.participantService.getWorkflowConfigurationObservers(observers);
                    return this.participantService.getClonedParticipants(
                        defaultThread,
                        workflowAssignees,
                        workflowObservers,
                    );
                } else {
                    const uniqueAssignees = new Set(Object.values(assignees || []).flat());
                    return this.participantService.getClonedParticipants(defaultThread, [...uniqueAssignees]);
                }
            }),
            startWith([]),
        );
    }

    private setIsWorkflowValid(workflowConfig$: Observable<IWorkflowConfiguration>): void {
        workflowConfig$.pipe(take(1)).subscribe((workflowConfig) => {
            if (!workflowConfig) {
                this.isWorkflowValid = true;
            } else {
                this.isWorkflowValid = !!workflowConfig?.steps;
            }
        });
    }

    private manageThreadTitle(selectedDesign: IWorkflowDesign, selectedDesignType: IWorkflowDesignType): void {
        if (!selectedDesignType?.titleTemplate) {
            this.customTitle$.next(selectedDesign.label);
            this.previewTitle$.next(selectedDesign.label);
        } else {
            this.customTitle$.next("");
            this.previewTitle$.next("");
        }
    }

    private validateSelectedTokens(designType: IWorkflowDesignType, control: SelectWorkflowTokenControl): boolean {
        const controls = this.workflowTokenKeys[designType?.workflowToken];

        return controls?.length === control?.workflowTokenIds?.length;
    }

    private subscribeToFormChanges(): Subscription {
        const formValues$ = combineLatest([
            this.selectedDesign$,
            this.selectedDesignType$,
            this.selectWorkflowTokenControl$,
            this.previewTitle$,
        ]);

        return formValues$.subscribe(
            ([selectedDesign, selectedDesignType, selectWorkflowTokenControl, previewTitle]) => {
                const isDesignControlsCompleted = !!selectedDesignType && !!selectedDesign && !!previewTitle;
                const designTypeRequiresToken = !!selectedDesignType?.workflowToken;
                const isTokenSelected = this.validateSelectedTokens(selectedDesignType, selectWorkflowTokenControl);
                const isTokenRequiredCompleted = designTypeRequiresToken ? isTokenSelected : true;

                this.isFormCompleted = isDesignControlsCompleted && isTokenRequiredCompleted;
            },
        );
    }

    private getWorkflowInputs(
        selectedDesign: IWorkflowDesign,
        workflowConfig: IWorkflowConfiguration,
        dueDates: Record<string, DateTime>,
        assignees: Record<string, string[]>,
        workflowTokens: IWorkflowToken[],
    ): IWorkflowInputs {
        const { unresolvedDesign, resolvedDesign } = WorkflowTokenResolver.resolveDesignWithTokens(
            workflowTokens,
            selectedDesign,
        );
        const configTokenInputs = WorkflowExtensionService.getConfigTokenInputs(unresolvedDesign);

        return this.getExtensionInputUpdates(resolvedDesign, workflowConfig, dueDates, assignees, configTokenInputs);
    }

    private setWorkflowConfigTokens(): void {
        this.configTokenSub?.unsubscribe();

        const configTokensIds$ = this.workflowConfiguration$.pipe(
            map((config) => config?.workflowTokens?.map((token) => token.id)),
            startWith([]),
        );

        this.configTokenSub = configTokensIds$.subscribe((configTokensIds) => {
            this.workflowTokenSelected({ workflowTokenIds: configTokensIds });
        });
    }
}
