<ng-container *ngIf="card$ | async; let card">
    <thread-card
        *ngIf="userId$ | async; let userId"
        title="Message"
        [thread]="thread$ | async"
        [card]="card$ | async"
        [replies]="replies$ | async"
        [invertTheme]="userId === card.createdBy"
        [loading]="loading"
        [edited]="card.status === cardStatuses.Edited"
        [isInternal]="card.isInternal"
        [edit]="edit$"
        (save)="save($event)"
    >
        <div
            card-actions
            *ngIf="allowEdit"
        >
            <fx-context-menu
                [gutter]="false"
                *ngIf="enableContextMenu$ | async"
            >
                <a
                    (click)="editMessage()"
                    *ngIf="canRemoveCard$ | async"
                    >Edit</a
                >
                <a
                    *ngIf="canForwardCard$ | async"
                    (click)="forwardMessage()"
                    >Forward</a
                >
                <a
                    (click)="removeMessage()"
                    *ngIf="canRemoveCard$ | async"
                    >Delete</a
                >
            </fx-context-menu>
        </div>
        <div card-loader>
            <loader-icon color="light"></loader-icon>
        </div>
        <div
            class="thread-card-error"
            *ngIf="errorMessage"
        >
            {{ errorMessage }}
        </div>
    </thread-card>
</ng-container>
