<loader-icon
    *ngIf="loader.counter"
    [static]="true"
    color="light"
></loader-icon>
<ng-container *ngIf="!loader.counter">
    <div
        *ngIf="tableData.data?.length; else noDocuments"
        class="vault-list"
    >
        <fx-table
            [tableData]="tableData"
            [trackBy]="trackDocument"
        >
            <ng-container
                *fxColumn="
                    'file.displayName';
                    label: tableHeaders.FileName;
                    sortable: true;
                    mobileCollapse: true;
                    mobileGrid: true;
                    sortAnalyticsEvent: gaEvents.VAULT_LIST_FILTER_DISPLAY_NAME;
                    let documentList
                "
            >
                <div class="vault-list-col vault-list-file-info">
                    <div class="vault-list-display-name">
                        <span
                            class="vault-list-label"
                            (click)="download(documentList.file.id, documentList.file.fileName, documentList.vaultId)"
                        >
                            <i
                                class="las la-chart-bar"
                                *ngIf="documentList.displayName === documentCategory.Report; else listIcon"
                            ></i>
                            <ng-template #listIcon>
                                <i
                                    class="las la-list-alt"
                                    *ngIf="documentList.cardType === vaultType.VaultRequest; else defaultIcon"
                                ></i>
                            </ng-template>
                            <ng-template #defaultIcon>
                                <i class="las la-file"></i>
                            </ng-template>
                            {{ documentList.file.displayName }}
                        </span>
                    </div>
                    <ng-container *ngIf="documentList.actions && documentList.actions.indexOf('sign') > -1">
                        <div class="vault-list-sign">
                            <signature-icon
                                *ngIf="documentList.signed"
                                theme="dark"
                                [cursor]="false"
                                >Signed by {{ documentList.signer | userToName | async }}
                                {{ documentList.signedOn | date : "dd MMM yyy" }}</signature-icon
                            >

                            <ng-container *ngIf="!documentList.signed">
                                <div class="document-sign">
                                    <signature-icon
                                        *ngIf="role$ | async | permissions : 'SignDocumentsVaultCard' | async"
                                        (click)="
                                            sign(
                                                documentList.thread.id,
                                                documentList.cardId,
                                                documentList.vaultId,
                                                documentList.file.id
                                            )
                                        "
                                        [analyticsClick]="gaEvents.VAULT_LIST_SIGN_DOCUMENT"
                                        theme="light"
                                        [cursor]="true"
                                        >Sign</signature-icon
                                    >
                                    <signature-icon
                                        *ngIf="!(role$ | async | permissions : 'SignDocumentsVaultCard' | async)"
                                        theme="dark"
                                        [cursor]="false"
                                        >Pending Signature</signature-icon
                                    >
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <ng-container
                *fxColumn="
                    'account.name';
                    label: tableHeaders.AccountName;
                    sortable: true;
                    mobileCollapse: true;
                    mobileGrid: true;
                    sortAnalyticsEvent: gaEvents.VAULT_LIST_FILTER_ACCOUNTS;
                    let documentList
                "
            >
                <a
                    class="vault-list-col"
                    [routerLink]="['/accounts', documentList.account.id]"
                >
                    <span class="mobile-cell-label">{{ tableHeaders.AccountName }}: </span>
                    <span> {{ documentList.account.name | titlecase }} </span>
                </a>
            </ng-container>
            <ng-container
                *fxColumn="
                    'thread.title';
                    label: tableHeaders.Workflow;
                    sortable: true;
                    mobileCollapse: true;
                    mobileGrid: true;
                    sortAnalyticsEvent: gaEvents.VAULT_LIST_FILTER_THREAD_TITLE;
                    let documentList
                "
            >
                <a
                    class="vault-list-col"
                    [routerLink]="['/timelines', documentList.thread.id]"
                >
                    <span class="mobile-cell-label">{{ tableHeaders.Workflow }}: </span>
                    <span class="vault-list-col"> {{ documentList.thread.title }} </span>
                </a>
            </ng-container>
            <ng-container
                *fxColumn="
                    'file.timestamp';
                    label: tableHeaders.DateAdded;
                    sortable: true;
                    mobileCollapse: true;
                    mobileGrid: true;
                    sortAnalyticsEvent: gaEvents.VAULT_LIST_FILTER_TIMESTAMP;
                    let documentList
                "
            >
                <span class="vault-list-col">
                    <span class="mobile-cell-label">{{ tableHeaders.DateAdded }}: </span>
                    <span>
                        {{ documentList.file.timestamp | timeAgo }}
                    </span>
                </span>
            </ng-container>

            <ng-container
                *fxColumn="
                    'actorName';
                    label: tableHeaders.UploadedBy;
                    sortable: true;
                    mobileCollapse: true;
                    mobileGrid: true;
                    sortAnalyticsEvent: gaEvents.VAULT_LIST_FILTER_CREATED_BY;
                    let documentList
                "
            >
                <div class="vault-list-col">
                    <span class="mobile-cell-label">{{ tableHeaders.UploadedBy }}: </span>
                    <fx-avatar
                        [name]="documentList.actorName"
                        [image]="documentList.actorId | profilePicture | async"
                        size="medium"
                    ></fx-avatar>
                </div>
            </ng-container>

            <ng-container *fxColumn="'contextmenu'; let documentList">
                <fx-context-menu [gutter]="false">
                    <a
                        *ngIf="
                            documentList.cardType !== vaultType.VaultRequest &&
                            (userId$ | async) === documentList.actorId &&
                            (role$ | async | permissions : 'EditVaultCard' | async)
                        "
                        class="menu-button"
                        [analyticsClick]="gaEvents.VAULT_LIST_CONTEXT_RENAME"
                        (click)="renameDocument(documentList.thread.id, documentList)"
                    >
                        <i class="las la-pencil-alt la-lg"></i>
                        <span>Rename</span>
                    </a>
                    <a
                        class="menu-button"
                        [analyticsClick]="gaEvents.VAULT_LIST_CONTEXT_DOWNLOAD"
                        (click)="download(documentList.file.id, documentList.file.fileName, documentList.vaultId)"
                    >
                        <i class="las la-download"></i>
                        <span>Download</span>
                    </a>
                    <a
                        class="menu-button"
                        [analyticsClick]="gaEvents.VAULT_LIST_CONTEXT_VIEW_IN_TIMELINE"
                        [routerLink]="['/timelines', documentList.thread.id, 'cards', documentList.cardId]"
                    >
                        <i class="las la-comments"></i>
                        <span>View in workflow</span>
                    </a>
                    <a
                        *ngIf="
                            documentList.cardType !== vaultType.VaultRequest &&
                            (userId$ | async) === documentList.actorId &&
                            (role$ | async | permissions : 'EditVaultCard' | async)
                        "
                        class="menu-button"
                        [analyticsClick]="gaEvents.VAULT_LIST_CONTEXT_DELETE"
                        (click)="
                            deleteItem(
                                documentList.thread.id,
                                documentList.vaultId,
                                documentList.file.id,
                                documentList.cardId
                            )
                        "
                    >
                        <i class="las la-trash-alt"></i>
                        <span>Delete</span>
                    </a>
                </fx-context-menu>
            </ng-container>
        </fx-table>
    </div>

    <ng-template #noDocuments>
        <div class="empty-state">
            <empty-state
                title="No files found"
                htmlText="There are no files matching your search.<br>Check the spelling or try removing filters."
                [imageType]="emptyStateImages.NotFound"
            ></empty-state>
        </div>
    </ng-template>
</ng-container>
