<div class="fx-onboarding fx-onboarding-bg-signup">
    <div class="fx-onboarding-container">
        <div class="fx-onboarding-content">
            <portal-brand type="onboarding"></portal-brand>
            <h1
                class="fx-onboarding-title"
                *ngIf="textResource.signUpTitle"
            >
                {{ textResource.signUpTitle }}
            </h1>
            <p
                class="fx-onboarding-body"
                *ngIf="textResource.signupInstruction"
            >
                {{ textResource.signupInstruction }}
            </p>
            <form
                class="fx-onboarding-form fx-form fx-form--dark"
                [formGroup]="form"
                (submit)="updateParentModel()"
            >
                <div
                    class="fx-form-group"
                    *ngIf="errorMessage"
                >
                    <div class="fx-form-validation-panel">
                        <div class="fx-form-validation-panel-item">{{ errorMessage }}</div>
                    </div>
                </div>
                <div class="fx-form-group">
                    <label
                        class="fx-form-label"
                        for="givenName"
                        >First name</label
                    >
                    <div class="fx-form-control">
                        <input
                            formControlName="givenName"
                            id="givenName"
                            class="fx-form-input"
                            type="text"
                            placeholder="eg. John"
                            aria-label="First name"
                            [ngClass]="{
                                'fx-form-input--error':
                                    !form.controls['givenName'].valid && form.controls['givenName'].touched
                            }"
                        />
                    </div>
                    <div
                        class="fx-form-validation"
                        *ngIf="form.controls['givenName'].hasError('required') && form.controls['givenName'].touched"
                    >
                        First name is required.
                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                    </div>
                </div>

                <div class="fx-form-group">
                    <label
                        class="fx-form-label"
                        for="familyName"
                        >Last name</label
                    >
                    <div class="fx-form-control">
                        <input
                            formControlName="familyName"
                            id="familyName"
                            class="fx-form-input"
                            type="text"
                            placeholder="eg. Doe"
                            aria-label="Last name"
                            [ngClass]="{
                                'fx-form-input--error':
                                    !form.controls['familyName'].valid && form.controls['familyName'].touched
                            }"
                        />
                    </div>
                    <div
                        class="fx-form-validation"
                        *ngIf="form.controls['familyName'].hasError('required') && form.controls['familyName'].touched"
                    >
                        Last name is required.
                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                    </div>
                </div>
                <div class="fx-form-group">
                    <label
                        class="fx-form-label"
                        for="emailAddress"
                        >Email</label
                    >
                    <div class="fx-form-control">
                        <input
                            formControlName="emailAddress"
                            id="emailAddress"
                            class="fx-form-input"
                            type="text"
                            placeholder="eg. john.doe@gmail.com"
                            aria-label="Email"
                            [ngClass]="{
                                'fx-form-input--error':
                                    !form.controls['emailAddress'].valid && form.controls['emailAddress'].touched
                            }"
                        />
                    </div>
                    <div
                        class="fx-form-validation"
                        *ngIf="
                            form.controls['emailAddress'].hasError('required') && form.controls['emailAddress'].touched
                        "
                    >
                        Email is required.
                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                    </div>
                    <div
                        class="fx-form-validation"
                        *ngIf="form.controls['emailAddress'].hasError('email') && form.controls['emailAddress'].touched"
                    >
                        Email is invalid.
                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                    </div>
                </div>
                <div class="fx-form-group">
                    <div class="fx-form-disclaimer">
                        <fx-checkbox
                            id="signup-agreed-to-terms-and-conditions-checkbox"
                            formControlName="agreedToTermsAndConditions"
                        ></fx-checkbox>
                        <label
                            for="signup-agreed-to-terms-and-conditions-checkbox"
                            class="fx-form-disclaimer-text"
                        >
                            I have read and understood the {{ appName
                            }}{{ appName[appName.length - 1].toLowerCase() === "s" ? "'" : "'s" }}
                            <a
                                *ngIf="termsUrl"
                                [href]="termsUrl"
                                target="_blank"
                                rel="noopener noreferrer"
                                class="fx-form-disclaimer-link"
                                role="button"
                                analyticsClick="onboarding_terms"
                                >Terms</a
                            >
                            <ng-container *ngIf="termsUrl && policyUrl"> &amp; </ng-container>
                            <a
                                *ngIf="policyUrl"
                                [href]="policyUrl"
                                target="_blank"
                                rel="noopener noreferrer"
                                class="fx-form-disclaimer-link"
                                role="button"
                                analyticsClick="onboarding_privacy"
                                >Privacy Policy</a
                            >
                            <ng-container *ngIf="(termsUrl || policyUrl) && termsOfBusinessUrl"> &amp; </ng-container>
                            <a
                                *ngIf="termsOfBusinessUrl"
                                [href]="termsOfBusinessUrl"
                                target="_blank"
                                rel="noopener noreferrer"
                                class="fx-form-disclaimer-link"
                                role="button"
                                analyticsClick="onboarding_termsofbusiness"
                                >Terms of Business</a
                            >
                            <ng-container *ngIf="(termsUrl || policyUrl || termsOfBusinessUrl) && disclaimerUrl">
                                &amp;
                            </ng-container>
                            <a
                                *ngIf="disclaimerUrl"
                                [href]="disclaimerUrl"
                                target="_blank"
                                rel="noopener noreferrer"
                                class="fx-form-disclaimer-link"
                                role="button"
                                analyticsClick="onboarding_disclaimer"
                                >Disclaimer and Disclosure</a
                            >
                        </label>
                    </div>
                    <div
                        class="fx-form-validation"
                        *ngIf="
                            form.controls['agreedToTermsAndConditions'].hasError('required') &&
                            form.controls['agreedToTermsAndConditions'].touched
                        "
                    >
                        You must agree and understand to continue.
                        <i class="fx-form-validation-icon las la-exclamation-triangle"></i>
                    </div>
                    <button
                        class="fx-onboarding-submit fx-onboarding-button fx-btn"
                        [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
                        [disabled]="!form.valid || form.controls['agreedToTermsAndConditions'].value === false"
                        analyticsClick="onboarding_getstarted"
                        type="submit"
                    >
                        {{ textResource.signupAction || textResource.default.signupAction }}
                    </button>
                </div>
            </form>
            <div class="fx-onboarding-actions">
                Already have an account?
                <a
                    class="fx-onboarding-action"
                    [routerLink]="['/login']"
                    analyticsClick="onboarding_login"
                    [queryParams]="{ emailAddress: form.controls['emailAddress'].value }"
                >
                    {{ textResource.loginAction || textResource.default.loginAction }}</a
                >
            </div>
        </div>
    </div>
</div>
