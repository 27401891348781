import { Component, Inject, Injector, OnInit } from "@angular/core";
import { IParticipant, IThread } from "@visoryplatform/threads";
import { AnalyticsService, GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { DialogRef, DialogService } from "projects/portal-modules/src/lib/shared/services/dialog.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";
import { ButtonType, IStepConfiguration, MultiComponentService } from "projects/portal-modules/src/lib/ui-layouts";
import { ENVIRONMENT } from "src/app/injection-token";
import { IMeetingRequestDetails } from "../../interfaces/IMeetingRequestDetails";
import { CalendarCardService } from "../../services/calendar-card.service";
import { CalendarModalService } from "../../services/calendar-modal.service";
import { ICreateInvitationCloseModalData } from "./close-modal/create-invitation-close-modal.component";

export enum ModalTitle {
    "editMeeting" = "Edit attendees",
    "createMeeting" = "Create meeting request",
}

export type MeetingRequestModalData = {
    meetingData: IMeetingRequestDetails;
    thread: IThread;
    edit: boolean;
};

@Component({
    selector: "calendar-meeting-request",
    templateUrl: "./calendar-meeting-request.component.html",
    styleUrls: ["./calendar-meeting-request.component.scss"],
})
export class CalendarMeetingRequestComponent implements OnInit {
    readonly theme = this.environment.theme;

    participants: IParticipant[] = [];
    modalTitle: string;
    editAttendees: boolean;
    loader = new Loader();
    meetingData: IMeetingRequestDetails;
    showValidationErrors = false;
    stepConfigurations: IStepConfiguration[] = [
        {
            stepIndex: 0,
            buttons: [
                {
                    title: "Send Request",
                    type: ButtonType.Finish,
                    isDisabled: false,
                    isHidden: false,
                },
            ],
        },
    ];

    private activeStepIndex = 0;
    private data: MeetingRequestModalData;
    private dialogRef: DialogRef<IParticipant[]>;

    constructor(
        private cardService: CalendarCardService,
        private multiComponentService: MultiComponentService,
        private analytics: AnalyticsService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private injector: Injector,
        private dialogService: DialogService,
        private calendarModalService: CalendarModalService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.dialogRef = await this.dialogService.getRef<IParticipant[]>(this.injector).toPromise();
        this.data = await this.dialogService.getData<MeetingRequestModalData>(this.injector).toPromise();

        if (this.data.edit) {
            this.modalTitle = ModalTitle.editMeeting;
            this.editAttendees = this.data.edit;
            this.stepConfigurations[0].buttons[0].title = "Send Update";
        } else {
            this.modalTitle = ModalTitle.createMeeting;
        }

        this.meetingData = this.data?.meetingData;
        this.participants = this.data?.thread.participants;
    }

    async submit(): Promise<void> {
        if (!this.calendarModalService.validateMeetingDetailsEventBody(this.participants, this.meetingData)) {
            this.showValidationErrors = true;
            this.stepConfigurations = this.multiComponentService.toggleForwardButtons(
                this.activeStepIndex,
                this.stepConfigurations,
                false,
            );

            return;
        }

        if (this.editAttendees) {
            this.dialogRef.close(this.meetingData.attendees);
        } else {
            await this.createRequest();
        }
    }

    async createRequest(): Promise<void> {
        this.analytics.recordEvent("calendar", GA_EVENTS.CALENDAR_CREATEINVITATION);
        if (!this.meetingData.meetingDescription) {
            const data: ICreateInvitationCloseModalData = {
                acceptButtonName: "Send",
                rejectButtonName: "Cancel",
                confirmDescription: "Are you sure you want to send this meeting request without a description?",
                confirmTitle: "Missing description",
            };
            const shouldSend = await this.calendarModalService.confirmClose(data);
            if (!shouldSend) {
                return;
            }
        }

        const { title, duration, attendees, recurrenceType, numberOfOccurrences, meetingDescription } =
            this.meetingData;

        await this.loader
            .wrap(
                this.cardService.createRequest(
                    this.data.thread.id,
                    title,
                    duration,
                    attendees,
                    recurrenceType,
                    numberOfOccurrences,
                    meetingDescription,
                ),
            )
            .toPromise();

        this.dialogRef.close();
    }

    async close(): Promise<void> {
        const data: ICreateInvitationCloseModalData = {
            acceptButtonName: "Discard",
            rejectButtonName: "Keep Editing",
            confirmDescription: "Are you sure you want to discard this meeting?",
            confirmTitle: "Discard meeting request",
        };
        const confirmClose = await this.calendarModalService.confirmClose(data);
        if (confirmClose) {
            this.dialogRef.close();
        }
    }

    handleMeetingDetailsUpdated(event: IMeetingRequestDetails): void {
        this.meetingData = event;
        this.showValidationErrors = false;
        this.stepConfigurations = this.multiComponentService.toggleForwardButtons(
            this.activeStepIndex,
            this.stepConfigurations,
            true,
        );
    }
}
