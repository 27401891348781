import { Component, Inject, Input, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { SignUpUserPayload } from "../../../services/onboarding.service";
import { EnvironmentSpecificConfig } from "../../../../environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";

@Component({
    selector: "app-signup",
    templateUrl: "./signup.component.html",
    styleUrls: ["./signup.component.scss"],
})
export class SignUpComponent implements OnInit {
    form = new UntypedFormGroup({
        givenName: new UntypedFormControl(null, Validators.required),
        familyName: new UntypedFormControl(null, Validators.required),
        emailAddress: new UntypedFormControl(null, [Validators.required, Validators.email]),
        agreedToTermsAndConditions: new UntypedFormControl(false, Validators.required),
    });

    policyUrl = this.environment.policyUrl;
    termsUrl = this.environment.termsUrl;
    termsOfBusinessUrl = this.environment.termsOfBusinessUrl;
    disclaimerUrl = this.environment.disclaimerUrl;
    readonly textResource = this.environment.featureFlags.text;
    readonly appName = this.environment.appName;
    readonly theme = this.environment.theme;

    @Input() userDataModel: SignUpUserPayload;
    @Input() errorMessage: string;
    @Output() progress: EventEmitter<SignUpUserPayload> = new EventEmitter();

    constructor(@Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {}

    ngOnInit() {
        this.form.setValue({
            familyName: this.userDataModel.familyName,
            givenName: this.userDataModel.givenName,
            emailAddress: this.userDataModel.emailAddress,
            agreedToTermsAndConditions: false,
        });
    }

    updateParentModel() {
        this.progress.emit(this.form.value);
    }
}
