<div class="match-loader">
    <div class="match-loader-man-tie">
        <!-- TODO: extract to SVG module -->
        <svg
            class="orbit"
            viewBox="0 0 160 160"
            width="100"
            height="160"
        >
            <circle
                cx="80"
                cy="80"
                r="50"
                fill="#DF0074"
            />
            <g transform="matrix(0.866, -0.5, 0.25, 0.433, 80, 80)">
                <path
                    d="M 0,70 A 65,70 0 0,0 65,0 5,5 0 0,1 75,0 75,70 0 0,1 0,70Z"
                    fill="#F8C6E0"
                >
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="360 0 0"
                        to="0 0 0"
                        dur="1s"
                        repeatCount="indefinite"
                    />
                </path>
            </g>
            <path
                d="M 50,0 A 50,50 0 0,0 -50,0Z"
                transform="matrix(0.866, -0.5, 0.5, 0.866, 80, 80)"
                fill="#DF0074"
            />
            <path
                class="logo"
                d="M30.4594 0.502463L19.9061 25.0394L12.0051 6.33662C10.9442 3.76847 9.57614 2.06568 7.90102 1.22824C6.22589 0.418719 4.29949 0 2.09391 0L0 0.111658V1.11658C1.03299 1.22824 1.9264 1.67488 2.6802 2.45649C3.43401 3.21018 4.18782 4.52217 4.94162 6.36453L16.5838 34H18.5939L33 0.502463H30.4594Z"
                fill="#FFFFFF"
                transform="translate(63,65)"
            />
        </svg>
        <!-- TODO: extract to SVG module -->
        <svg
            width="247"
            height="232"
            viewBox="0 0 247 232"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M65.2871 19.7634L72.6862 29.796L83.2843 38.2798L76.1209 45.8469L72.0279 46.5679L67.8716 42.0158L59.3067 32.447L65.2871 19.7634Z"
                fill="url(#paint0_linear)"
            />
            <path
                d="M45.2653 17.7604L46.591 34.4642C46.6601 35.3216 46.938 35.5547 47.6582 35.3314L49.527 34.747L53.9641 41.4409C54.8618 42.7934 56.1635 42.8046 57.209 41.9465L64.2552 36.151C65.4122 35.1985 65.9773 34.0493 65.4443 31.8203L62.8293 20.9101L56.4415 12.6381L45.2653 17.7604Z"
                fill="#FDB79F"
            />
            <path
                d="M53.2064 15.005C50.6368 16.6066 46.8946 18.8399 44.3237 18.418C41.6843 17.9789 40.3703 14.6271 41.9026 13.3949C42.8402 12.6415 44.1229 12.6989 46.5653 10.5624C47.7685 9.50867 55.3612 2.71311 61.7401 4.69327C68.1189 6.67343 70.8218 12.7973 70.6832 18.8011C70.5446 24.805 71.6014 28.2084 72.6759 29.8163C68.0005 31.4124 66.6307 29.2456 66.0021 27.7231C65.3752 26.2059 63.9888 22.2608 63.2033 22.0486C62.4177 21.8365 61.2366 22.4254 61.7127 24.1074L62.1664 25.7226C62.2935 26.1718 61.3576 26.6387 61.1567 26.277L60.3137 24.7634C59.8485 23.9354 58.3652 24.0478 57.4177 22.6319C56.6487 21.4765 56.6455 19.122 56.4368 17.2306C56.1454 14.5951 54.5401 14.1707 53.2064 15.005Z"
                fill="#3A250B"
            />
            <path
                d="M61.649 23.1276C61.6944 21.6887 63.4738 20.8028 64.9371 21.6062C67.6681 23.1067 66.2819 27.6005 64.0667 28.1918L61.649 23.1276Z"
                fill="#FCB8A0"
            />
            <path
                d="M52.3009 33.8767L50.7129 36.534L49.532 34.7443L52.3009 33.8767Z"
                fill="url(#paint1_linear)"
            />
            <path
                d="M15.4031 75.2742C16.8727 74.8892 19.5136 73.7287 20.9344 72.8447L28.4886 79.4227L24.8769 80.8327C22.4474 81.0225 19.6005 80.4096 17.8001 79.8457L15.4031 75.2742Z"
                fill="#EE7868"
            />
            <path
                d="M1.85194 53.8104C0.0949135 55.1987 1.45614 59.7811 4.77496 63.8754C8.09378 67.9697 12.3779 70.2527 14.1349 68.859C15.8865 67.4653 14.5633 62.8287 11.1306 58.832C8.66317 55.9687 4.35732 51.831 1.85194 53.8104Z"
                fill="#5F91A7"
            />
            <path
                d="M1.65132 54.5099C0.0678272 56.1259 1.16327 60.41 4.5092 64.5423C7.86056 68.6799 11.8084 70.4424 13.5817 69.0379C15.3496 67.6333 14.1619 63.6746 10.9244 59.4502C8.87998 56.7821 3.63068 52.4925 1.65132 54.5099Z"
                fill="#CDDCE3"
            />
            <path
                d="M5.10048 64.0814C7.59502 67.1616 12.6763 70.8709 14.5634 68.3384C14.661 68.2028 14.8944 68.2245 14.8239 68.4251C14.6178 68.9945 13.8368 69.8297 13.2348 70.1822C11.3314 71.2939 7.25884 69.3254 3.78817 65.0412C0.626615 61.1422 -0.756218 56.9287 0.409709 55.1066C0.52359 54.9276 1.43461 53.8159 1.59729 53.6857C1.85217 53.4851 2.20465 53.5609 1.98773 53.7833C0.00836563 55.7898 2.10703 60.3884 5.10048 64.0814Z"
                fill="#5F91A7"
            />
            <path
                d="M13.2132 70.1771L22.2966 81.3104C22.866 81.2236 23.3486 80.844 23.4246 80.4915L14.3683 69.1631C14.0267 69.3692 13.4843 69.7921 13.2132 70.1771Z"
                fill="#243048"
            />
            <path
                d="M2.07921 53.3443C1.94906 53.4039 1.82424 53.4798 1.71578 53.5666C-0.122583 55.0253 1.20601 59.6782 4.67667 63.9677C8.14734 68.2518 12.4478 70.5456 14.2862 69.0869C16.1246 67.6281 14.7958 62.9753 11.3252 58.6858C8.09854 54.7053 4.10195 52.3953 2.07921 53.3443ZM2.41536 53.8214C4.23204 53.0297 8.06605 55.3887 10.9727 58.9732C14.0692 62.7963 15.6093 67.3191 13.9716 68.6206C12.3339 69.9221 8.142 67.5142 5.0401 63.6857C1.93819 59.8571 0.441489 55.3236 2.07921 54.0221C2.18224 53.9408 2.29606 53.8757 2.41536 53.8214Z"
                fill="#355260"
            />
            <path
                d="M3.2411 55.6699C4.78663 55.9465 6.08815 56.9063 7.87229 58.5223C8.35493 58.9616 8.91884 58.3814 8.46873 57.9042C7.17808 56.5376 4.97643 54.965 3.36582 54.8186C2.81811 54.7698 2.61747 55.556 3.2411 55.6699ZM8.95687 59.6612L9.26602 60.0299C9.68358 60.5234 10.3452 59.9811 9.92217 59.4768L9.61303 59.1081C9.20088 58.6254 8.55558 59.1894 8.95687 59.6612Z"
                fill="white"
            />
            <path
                d="M12.4693 76.0235C13.3369 74.4292 14.4215 73.5724 16.2599 72.6885C17.3336 73.4043 19.6004 75.8392 20.5928 77.5908C19.9691 78.3609 19.1341 79.0983 17.8055 79.8467C15.9996 79.3803 13.3803 77.3847 12.4693 76.0235Z"
                fill="#FDB79F"
            />
            <path
                d="M28.9008 79.245C26.0537 72.667 21.1188 70.4218 15.6796 70.0693L15.4736 71.4848C17.1873 71.6258 18.7544 72.1572 19.9962 73.6594C20.1644 76.8806 22.8163 79.4565 26.2002 80.4543L28.9008 79.245Z"
                fill="#FDB79F"
            />
            <path
                d="M17.4421 73.4961C16.2599 74.1956 14.4432 75.432 13.1146 76.9884L13.4292 77.2976C14.8825 75.6653 16.5799 74.4288 17.7404 73.7618L17.4421 73.4961Z"
                fill="#D65B52"
            />
            <path
                d="M18.5377 74.7158C17.388 75.3991 15.6364 76.5813 14.3241 78.078L14.6603 78.3437C16.0595 76.809 17.6591 75.6486 18.7762 75.0087L18.5377 74.7158Z"
                fill="#D65B52"
            />
            <path
                d="M19.6923 76.1152C18.6402 76.7389 17.0838 77.7801 15.8202 79.0978L16.2052 79.3256C17.4959 78.0024 18.8896 76.9991 19.9037 76.4188L19.6923 76.1152Z"
                fill="#D65B52"
            />
            <path
                d="M91.4063 220.42L93.5483 228.804L89.7197 229.785L89.0256 228.733L86.8186 230.734L76.965 231.526C75.5605 231.64 75.0562 230.631 76.7699 229.514L85.2893 224.064L85.0614 221.185L91.4063 220.42Z"
                fill="#3B1A1F"
            />
            <path
                d="M235.819 213.104L246.984 217.534L244.978 220.94L243.741 220.685L243.6 223.662L238.21 231.232C237.261 232.566 236.166 232.089 236.182 230.359L237.755 218.071L235.819 213.104Z"
                fill="#3B1A1F"
            />
            <path
                d="M99.7518 87.834C102.669 89.8513 102.642 91.2233 109.481 93.7883C113.033 94.7699 115.196 95.1062 121.151 95.9088L126.042 90.6593L99.7518 87.834Z"
                fill="#022340"
            />
            <path
                d="M126.731 62.5418L147.729 86.3917C143.255 90.1498 135.782 96.8308 128.629 93.5445L117.035 88.8483L75.1483 57.4876L73.7492 43.0138L75.2296 32.2439L79.324 31.0889L126.731 62.5418Z"
                fill="url(#paint2_linear)"
            />
            <path
                d="M128.63 93.5448C127.838 100.605 124.422 96.6629 116.629 93.9026C105.431 91.0502 79.7207 81.0124 58.0507 61.9563L66.3207 45.2971L73.7446 43.0195C89.5253 66.7339 110.094 82.9321 128.63 93.5448Z"
                fill="#A1D9DE"
            />
            <path
                d="M78.1635 41.2793L76.7481 47.8464L71.7752 47.4994L71.5964 44.9722L75.3544 40.6719L78.1635 41.2793Z"
                fill="#DF0074"
            />
            <path
                d="M81.8894 225.244L93.2666 221.698C79.6009 151.189 79.1399 147.474 144.47 124.758L128.629 93.5488C125.82 94.3026 121.249 94.8503 116.629 93.9067C54.0699 133.684 53.9831 149.611 81.8894 225.244Z"
                fill="url(#paint3_linear)"
            />
            <path
                d="M128.628 93.5449C124.257 94.559 120.402 94.5698 116.628 93.9028C110.445 97.368 86.7797 113.246 78.5803 122.937C86.3513 130.063 96.5844 135.562 109.144 138.485C121.72 133.018 127.088 131.023 136.633 127.503L128.628 93.5449Z"
                fill="url(#paint4_linear)"
            />
            <path
                d="M235.53 225.029L242.342 214.329C176.562 174.737 173.107 168.658 171.996 107.916C171.838 99.4232 166.388 77.173 153.894 68.0137C151.953 74.3314 147.934 80.0689 143.33 84.4831C138.704 88.9136 133.482 92.0209 128.634 93.5448C148.4 183.364 155.233 189.411 235.53 225.029Z"
                fill="url(#paint5_linear)"
            />
            <path
                d="M187.289 80.4424C189.892 84.0486 189.751 86.6679 188.791 88.5768C187.072 91.9986 186.14 94.5202 188.873 98.8097C188.065 99.7967 186.308 101.369 185.001 101.749C181.389 97.7795 178.743 93.3922 182.593 83.452L182.219 80.4965L187.289 80.4424Z"
                fill="#FDB79F"
            />
            <path
                d="M187.772 100.118C184.968 97.2004 183.694 93.8544 184.171 90.6982C183.255 93.144 184.361 96.94 187.436 100.346L187.772 100.118Z"
                fill="#D65B52"
            />
            <path
                d="M186.833 100.92C183.78 98.3438 182.31 94.0109 182.961 90.8818C181.887 93.4469 183.118 97.8611 186.497 101.153L186.833 100.92Z"
                fill="#D65B52"
            />
            <path
                d="M185.923 101.468C182.702 98.3552 181.411 94.8953 181.834 90.877C180.864 93.686 182.095 98.2358 185.609 101.69L185.923 101.468Z"
                fill="#D65B52"
            />
            <path
                d="M79.0888 29.99L75.2845 30.1352C75.2075 34.47 74.7297 40.107 73.6041 43.2791L80.4482 44.4336C81.1355 38.7489 80.2089 32.4012 79.0888 29.99Z"
                fill="white"
            />
            <path
                d="M65.9837 46.0482L66.3986 43.1725C68.5909 43.2417 71.316 43.2197 74.3086 42.7598L73.2223 48.5731C70.4364 47.8843 67.4641 46.8681 65.9837 46.0482Z"
                fill="white"
            />
            <path
                d="M23.8968 81.2897L29.4607 77.7865C53.072 108.139 56.0329 111.04 58.8637 109.999C61.2606 109.12 60.7237 97.3741 56.6945 63.3887L82.4316 74.2345C79.1887 102.862 77.1551 123.795 64.6173 126.625C52.1772 129.429 43.967 117.829 23.8968 81.2897Z"
                fill="url(#paint6_linear)"
            />
            <path
                d="M61.532 65.6006C63.4192 69.1255 70.2249 76.8314 81.472 82.6882L81.613 81.4897L61.532 65.6006Z"
                fill="#033059"
            />
            <path
                d="M65.1704 45.6924C74.655 67.1997 93.2773 85.0953 109.655 93.8533C96.2816 90.7243 71.6454 77.4543 56.6999 63.3927L65.1704 45.6924Z"
                fill="#044077"
            />
            <path
                d="M100.083 11.9297L76.3416 29.424C90.067 50.5842 113.57 67.764 143.32 84.4828L146.715 109.482C147.854 117.845 151.932 120.627 159.334 118.75L175.163 114.737C174.458 108.132 171.92 94.8894 166.839 83.5393C161.872 72.4331 146.335 53.8922 129.849 37.5855C119.519 27.3633 108.809 18.0196 100.083 11.9297Z"
                fill="#044077"
            />
            <path
                d="M106.39 21.8154C109.682 27.4173 118.05 38.1005 130.804 38.5289L129.855 37.5853L106.39 21.8154Z"
                fill="#033059"
            />
            <path
                d="M62.4972 51.2789L62.7466 50.7529C73.0447 63.2799 86.423 81.4845 105.593 91.5169C90.1377 84.8901 79.7691 78.6321 72.5024 71.9023L74.4113 67.9598L68.2725 66.8372C65.7183 62.9165 63.4787 55.7203 62.4972 51.2789Z"
                fill="#033059"
            />
            <path
                d="M65.0296 44.5527L62.0253 48.2458C64.1836 55.8975 66.136 61.4127 69.1836 66.0872L75.1326 67.5514L73.6195 71.5534C79.6173 77.5729 88.9826 82.9579 105.593 91.5152C83.5055 76.7215 71.0924 58.6198 65.0296 44.5527Z"
                fill="#0B568F"
            />
            <path
                d="M188.607 81.8527L181.753 83.6151C162.778 39.9336 162.111 38.323 129.855 37.5855C118.304 35.194 107.757 26.5336 100.084 11.9297C169.421 17.6617 176.579 24.8633 188.607 81.8527Z"
                fill="url(#paint7_linear)"
            />
            <path
                d="M81.9593 25.2861L81.249 25.8067L100.213 51.636L131.281 77.4654C125.495 67.9211 116.943 55.5134 111.308 48.4582L101.894 49.456L101.753 37.5961C97.3007 33.491 90.4082 29.4401 81.9593 25.2861Z"
                fill="#033059"
            />
            <path
                d="M80.1 25.2158L75.1435 28.5889C90.0782 49.9714 107.578 64.0656 131.271 77.4711C121.602 63.7891 114.861 55.1882 110.376 49.4236L101.304 50.0474L100.539 38.4857C94.6062 32.8784 89.1671 29.8904 80.1 25.2158Z"
                fill="#0B568F"
            />
            <g class="tie">
                <path
                    d="M74.3837 47.0379C82.0463 60.7308 91.9431 67.5582 107.323 71.0831C94.2153 63.529 84.2589 56.3327 76.7699 45.1182L74.3837 47.0379Z"
                    fill="#DF0074"
                />
                <path
                    class="tie-bottom"
                    d="M159.388 78.3388L161.498 83.2248L155.972 84.9764C138.597 76.3974 119.703 78.2304 107.323 71.083C121.509 74.6079 142.165 71.1046 159.388 78.3388Z"
                    fill="url(#paint8_linear)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="73.826"
                    y1="36.1534"
                    x2="72.8036"
                    y2="29.0542"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FDB79F" />
                    <stop
                        offset="1"
                        stop-color="#EE7868"
                    />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="50.9365"
                    y1="34.539"
                    x2="50.9306"
                    y2="33.8828"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FCB8A0" />
                    <stop
                        offset="1"
                        stop-color="#EB8975"
                    />
                </linearGradient>
                <linearGradient
                    id="paint2_linear"
                    x1="77.2681"
                    y1="38.8304"
                    x2="104.634"
                    y2="63.1078"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#C0E5E9" />
                    <stop
                        offset="1"
                        stop-color="#E2F4F4"
                    />
                </linearGradient>
                <linearGradient
                    id="paint3_linear"
                    x1="61.0551"
                    y1="223.374"
                    x2="87.983"
                    y2="168.672"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#033460" />
                    <stop
                        offset="1"
                        stop-color="#02284A"
                    />
                </linearGradient>
                <linearGradient
                    id="paint4_linear"
                    x1="124.94"
                    y1="111.339"
                    x2="101.135"
                    y2="121.625"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#022340" />
                    <stop
                        offset="1"
                        stop-color="#022748"
                    />
                </linearGradient>
                <linearGradient
                    id="paint5_linear"
                    x1="264.608"
                    y1="225.191"
                    x2="203.21"
                    y2="167.678"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#044077" />
                    <stop
                        offset="1"
                        stop-color="#033460"
                    />
                </linearGradient>
                <linearGradient
                    id="paint6_linear"
                    x1="60.8334"
                    y1="95.0654"
                    x2="39.9373"
                    y2="105.805"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#044077" />
                    <stop
                        offset="1"
                        stop-color="#257AC1"
                    />
                </linearGradient>
                <linearGradient
                    id="paint7_linear"
                    x1="140.965"
                    y1="29.7286"
                    x2="167.584"
                    y2="55.3549"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#044077" />
                    <stop
                        offset="1"
                        stop-color="#257AC1"
                    />
                </linearGradient>
                <linearGradient
                    id="paint8_linear"
                    x1="155.224"
                    y1="82.6423"
                    x2="154.244"
                    y2="73.5866"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#DF0074" />
                    <stop
                        offset="1"
                        stop-color="#A80057"
                    />
                </linearGradient>
            </defs>
        </svg>
    </div>
    <div class="match-loader-title">
        {{ textResource.selectStaffMemberDescription }}
    </div>
    <div class="match-loader-subtitle">
        {{ textResource.matchLoaderDescription }}
    </div>
</div>
