import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { ScrollingModule as ExperimentalScrollingModule } from "@angular/cdk-experimental/scrolling";
import { ListNotificationsComponent } from "./components/list-notifications/list-notifications.component";
import { NotificationComponent } from "./components/notification/notification.component";
import { NotificationChannelDirective } from "./directives/notification-channel.directive";
import { NotificationIconPipe } from "./pipes/notification-icon.pipe";
import { ViewportObserverService } from "./services/viewport-observer.service";
import { CountNotificationsPipe } from "./pipes/count-notifications.pipe";
import { FxUiModule } from "@visoryplatform/fx-ui";
import { TimeAgoPipe } from "./pipes/time-ago.pipe";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PortalUiModule } from "@visoryplatform/portal-ui";

const exportDeclarations = [ListNotificationsComponent, NotificationComponent, NotificationChannelDirective];

@NgModule({
    imports: [
        CommonModule,
        FxUiModule,
        ScrollingModule,
        ExperimentalScrollingModule,
        MatCheckboxModule,
        FormsModule,
        PortalUiModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
    ],
    declarations: [NotificationIconPipe, CountNotificationsPipe, TimeAgoPipe, ...exportDeclarations],
    exports: [...exportDeclarations],
    providers: [ViewportObserverService],
})
export class NotificationsFrontendModule {}
