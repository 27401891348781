<deprecated-sidebar-modal-layout
    (close)="closeModal()"
    [contentGutters]="false"
    [header]="'Request for information'"
    [subHeader]="thread.title"
>
    <div modal-body>
        <div
            *ngFor="let group of vaultGroups; trackBy: trackGroup; let groupIndex = index"
            class="upload-rfi-group"
        >
            <div class="fx-l-component">
                <fx-accordion
                    [type]="'secondary'"
                    [custom]="true"
                    [disabled]="group.loading"
                    [toggleGutters]="true"
                    [isExpanded]="groupIndex === 0"
                >
                    <ng-container accordion-header>
                        <div
                            class="upload-rfi-group-title"
                            [analyticsClick]="gaEvents.RFI_COLLAPSE"
                            [analyticsLabel]="group.displayName"
                        >
                            {{ group.displayName }}
                        </div>
                        <i
                            class="fx-accordion-toggle-icon las la-angle-down"
                            [analyticsClick]="gaEvents.RFI_COLLAPSE"
                            [analyticsLabel]="group.displayName"
                        ></i>
                    </ng-container>

                    <div accordion-content>
                        <div class="upload-rfi-group-items">
                            <upload-item
                                *ngFor="let item of group.items; trackBy: trackItem; let i = index"
                                [role]="role"
                                [item]="item"
                                [closed]="closed"
                                (uploadFile)="uploadFile(group, item, $event)"
                                (deleteFile)="deleteFile(group, item, $event)"
                                (openFile)="openFile(item, $event)"
                                [expandInitial]="groupIndex === 0 && i === 0"
                            >
                            </upload-item>
                        </div>
                    </div>
                </fx-accordion>
            </div>
        </div>
    </div>
    <div modal-footer>
        <div
            *ngIf="!closed || (role | permissions : 'CreateVaultCard' | async)"
            [ngSwitch]="uploadInProgress(vaultGroups)"
            class="status-indicator"
        >
            <span *ngSwitchCase="true"><i class="las la-spinner"> </i>Uploading</span>
            <span *ngSwitchDefault><i class="las la-check"> </i>Saved</span>
        </div>

        <ng-container
            [ngSwitch]="
                !closed &&
                (role | permissions : 'CreateVaultCard' | async) &&
                !(role | permissions : 'UploadSignableDocumentVaultCard' | async)
            "
        >
            <button
                *ngSwitchDefault
                class="fx-btn fx-btn--primary fx-btn--primary-outlined fx-btn--full-width"
                role="button"
                [analyticsClick]="gaEvents.RFI_CLOSE"
                (click)="closeModal()"
            >
                Close
            </button>

            <button
                *ngSwitchCase="true"
                class="fx-btn fx-btn--primary fx-btn--primary-outlined fx-btn--full-width"
                role="button"
                [disabled]="uploadInProgress(vaultGroups)"
                [analyticsClick]="gaEvents.RFI_COMPLETE"
                (click)="completeRfi()"
            >
                Complete
            </button>
        </ng-container>
    </div>
</deprecated-sidebar-modal-layout>
