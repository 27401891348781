import { Injectable, Injector } from "@angular/core";
import { IPluginFactory } from "projects/portal-modules/src/lib/plugins";
import { Libraries, TaskAction } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import { BILL_APPROVAL_CARD_TYPE } from "@visoryplatform/threads";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags/enums/LaunchDarklyFeatureFlags";
import { CreateBillApprovalComponent } from "./components/create-bill-approval/create-bill-approval.component";
import { BillApprovalCardComponent } from "./components/bill-approval-card/bill-approval-card.component";
import { BillApprovalActions } from "./interfaces/BillApproval";
import { CardResources } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { DialogService } from "projects/portal-modules/src/lib/shared/services/dialog.service";
import { ViewBillApprovalComponent } from "./components/view-bill-approval/view-bill-approval.component";
import { EditBillApprovalComponent } from "./components/edit-bill-approval/edit-bill-approval.component";

type BillApprovalRequestOptions = { focusReplyInput: boolean };

export async function viewBillApprovalActionActionFactory(
    cardResources: Partial<CardResources>,
    injector: Injector,
    options: BillApprovalRequestOptions,
): Promise<boolean> {
    const dialogService = injector.get(DialogService);
    const { thread$, card$, state$, role, replies$ } = cardResources;
    const focusReplyInput = options.focusReplyInput;
    const data = { state$, thread$, replies$, card$, role, readonly: false, focusReplyInput };

    const config = {
        data,
        panelClass: ["centered-modal"],
        disableClose: true,
        width: "761px",
        autoFocus: false,
    };

    return dialogService.open<boolean>(ViewBillApprovalComponent, config).toPromise();
}

export async function editBillApprovalActionActionFactory(
    cardResources: Partial<CardResources>,
    injector: Injector,
    options: BillApprovalRequestOptions,
): Promise<boolean> {
    const dialogService = injector.get(DialogService);
    const { thread$, card$, state$, role, replies$ } = cardResources;
    const focusReplyInput = options.focusReplyInput;
    const data = { state$, thread$, replies$, card$, role, readonly: false, focusReplyInput };

    const config = {
        data,
        panelClass: ["centered-modal"],
        disableClose: true,
        width: "761px",
        autoFocus: false,
    };

    return dialogService.open<boolean>(EditBillApprovalComponent, config).toPromise();
}

@Injectable()
export class BillApprovalPlugin implements IPluginFactory {
    readonly id = "BillApprovalPlugin";
    readonly gaEvents = GA_EVENTS;

    constructor(private libraries: Libraries) {
        this.registerExtensions();
    }

    private registerExtensions(): void {
        const viewAccountsPayableAction: TaskAction<boolean> = {
            analyticsEvents: [GA_EVENTS.BILL_APPROVAL_VIEW],
            cardTypes: [BILL_APPROVAL_CARD_TYPE],
            action: viewBillApprovalActionActionFactory,
            buttonLabel: "View",
            statusIcon: "las la-check-square",
        };

        const editAccountsPayableAction: TaskAction<boolean> = {
            analyticsEvents: [GA_EVENTS.BILL_APPROVAL_EDIT],
            cardTypes: [BILL_APPROVAL_CARD_TYPE],
            action: editBillApprovalActionActionFactory,
            buttonLabel: "Edit",
            statusIcon: "las la-check-square",
        };

        this.libraries.taskActions.register(BillApprovalActions.ViewBillApproval, viewAccountsPayableAction);
        this.libraries.taskActions.register(BillApprovalActions.EditBillApproval, editAccountsPayableAction);
        this.libraries.cardViews.register(BILL_APPROVAL_CARD_TYPE, BillApprovalCardComponent);
        this.libraries.createCard.register(BILL_APPROVAL_CARD_TYPE, {
            title: "Bill approval",
            tooltipMessage: "Review and approve a list of bills for payment",
            analyticsEvent: this.gaEvents.APP_CREATE_BILL_APPROVAL_CARD,
            permission: ["CreateBillApprovalCard"],
            featureFlags: [LaunchDarklyFeatureFlags.EnableBillApproval],
            icon: "la-money-check-alt",
            disableInternalCreation: true,
            componentRef: CreateBillApprovalComponent,
            config: {
                panelClass: ["centered-modal"],
                disableClose: false,
            },
        });
    }
}
