import { Injectable } from "@angular/core";
import { IWorkflowStepUI } from "../../interfaces/IWorkflowStepUI";
import {
    ASSIGNEE_EXTENSION_TYPE,
    AssigneeExtensionHelpers,
    InputType,
    IStep,
    IWorkflow,
    MILESTONE_EXTENSION_TYPE,
    WORKFLOW_EXTENSION_TYPE,
    AssigneeType,
    WorkflowTypesService,
    WorkflowGraphService,
} from "@visoryplatform/workflow-core";
import { IStepUI } from "../../interfaces/IStepUi";
import { DueDateAnchor, IWorkflowConfigurationSteps, WorkflowDueDateService } from "@visoryplatform/threads";
import { DateTime } from "luxon";

@Injectable({
    providedIn: "root",
})
export class WorkflowStepsService {
    getUpdatedStepEntries(startDate: string, workflow: IWorkflow): IWorkflowStepUI[] {
        if (!workflow) {
            return null;
        }

        const dateNow = DateTime.now();
        const workflowStart = startDate ? DateTime.fromISO(startDate, { setZone: true }) : dateNow;

        const steps = WorkflowGraphService.orderWorkflowSteps(workflow);
        const dueDateSteps = WorkflowDueDateService.filterDueDateSteps(steps);
        const dueDates = WorkflowDueDateService.getDueDates(DueDateAnchor.StartDate, workflowStart, dueDateSteps);

        const currentStepIndex = steps.findIndex((step) => step.id === workflow.currentStepId);

        const allSteps = steps.map((step, index) => {
            const stepDate = dueDates[step.id];
            return this.mapStepToStepEntry(step, index, currentStepIndex, stepDate?.dueDate, stepDate?.isOverdue);
        });

        return allSteps;
    }

    getStepsUI(steps: IStep[], workflowConfigurationSteps?: IWorkflowConfigurationSteps): IStepUI[] {
        const filteredSteps = steps.filter((step) =>
            step.extensions.find((ext) => ext?.type !== WORKFLOW_EXTENSION_TYPE),
        );

        return filteredSteps.map((step) => {
            const stepAssignees = this.getStepAssignees(step, workflowConfigurationSteps);
            const isAssignedToClient = AssigneeExtensionHelpers.isAssignedToClient(step.extensions);

            return {
                id: step.id,
                label: step.label,
                assignees: stepAssignees,
                isAssignedToClient,
            };
        });
    }

    getStepAssignees(step: IStep, workflowConfigurationSteps?: IWorkflowConfigurationSteps): string[] {
        if (workflowConfigurationSteps) {
            const stepConfig = workflowConfigurationSteps[step.id];
            return stepConfig?.assignees ?? [];
        } else {
            const stepAssignees = WorkflowTypesService.getStaticExtensionsInput(step, ASSIGNEE_EXTENSION_TYPE);
            return stepAssignees?.data?.assignees ?? [];
        }
    }

    private mapStepToStepEntry(
        step: IStep,
        index: number,
        currentStepIndex: number,
        dueDate: DateTime,
        isOverdue: boolean,
    ): IWorkflowStepUI {
        const isCompleted = index < currentStepIndex;

        return this.getStepEntry(step, isCompleted, dueDate, isOverdue);
    }

    private isMilestone(step: IStep): boolean {
        const milestoneExtension = step.extensions?.find((ext) => ext?.type === MILESTONE_EXTENSION_TYPE);
        return !!milestoneExtension?.inputs?.find(
            (input) => input.type === InputType.Static && (input.data as any)?.isMilestone,
        );
    }

    private getStepEntry(step: IStep, isCompleted: boolean, dueDate: DateTime, isOverdue: boolean): IWorkflowStepUI {
        const timingsOutput = WorkflowDueDateService.getTimings(step);
        const completedDate = timingsOutput?.completedDate || null;

        const isMilestone = this.isMilestone(step);
        const assignee = AssigneeExtensionHelpers.getAssigneeData(step.extensions);

        const hideFromExternal = assignee?.hideFrom?.includes(AssigneeType.External);

        return {
            step,
            isCompleted,
            dueDate: dueDate?.toISO(),
            isOverdue,
            assignee: assignee || null,
            completedDate,
            isMilestone,
            hideFromExternal,
        };
    }
}
