<div
    [ngClass]="{ 'thread-search--with-tooltip': tooltip }"
    class="thread-search"
>
    <div class="fx-form-control thread-search-box">
        <div class="fx-form-icon">
            <i class="las la-search"></i>
        </div>
        <ng-container>
            <input
                #search
                class="fx-form-input threads-filter-search"
                [placeholder]="placeholder"
                [attr.aria-label]="placeholder"
                [analyticsClick]="analyticsClickEvent"
                type="text"
                [formControl]="formControl"
            />
        </ng-container>
    </div>
    <div
        *ngIf="tooltip"
        class="thread-search-hint"
    >
        <i
            class="las la-exclamation-circle"
            [tuiHint]="tooltip"
            tuiHintDirection="top"
        ></i>
    </div>
</div>
