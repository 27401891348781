import { Inject, Injectable } from "@angular/core";
import { Observable, ReplaySubject, Subscription } from "rxjs";
import { EnvironmentSpecificConfig } from "../../environment/environment.common";
import { initialize, LDClient } from "launchdarkly-js-client-sdk";
import { AppUser } from "../../findex-auth";
import { ENVIRONMENT } from "src/app/injection-token";
import { Role } from "@visoryplatform/threads";
import { map, scan, shareReplay, startWith } from "rxjs/operators";
import { LDFlagSet } from "launchdarkly-js-sdk-common";
import { LaunchDarklyFeatureFlags } from "../enums/LaunchDarklyFeatureFlags";
import { ILaunchDarklyFeatureFlags } from "../interfaces/ILaunchDarklyFeatureFlags";

interface ILaunchDarklyUserContext {
    key: string;
    name: string;
    anonymous: boolean;
    role: Role;
}

interface ILaunchDarklyFeatureFlagUpdates {
    [key: string]: {
        current: boolean;
        previous: boolean;
    };
}

@Injectable({ providedIn: "root" })
export class FeatureFlagService {
    readonly configId = this.environment.launchDarkly?.clientSideId;
    private ldClient: LDClient;
    private flags$ = new ReplaySubject<ILaunchDarklyFeatureFlags>(1);
    private flagUpdates$ = new ReplaySubject<ILaunchDarklyFeatureFlags>(1);
    private flagUpdatesSub: Subscription;

    constructor(@Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {}

    async init(user: AppUser): Promise<void> {
        this.flagUpdatesSub?.unsubscribe();

        const clientSideId = this.environment.launchDarkly?.clientSideId;
        const userContext = this.buildUserContext(user);

        if (this.ldClient) {
            await this.ldClient.close();
        }

        if (clientSideId) {
            this.ldClient = initialize(this.configId, userContext);

            await this.ldClient.waitUntilReady();

            this.setFlags();

            this.ldClient.on("change", (flagUpdates: ILaunchDarklyFeatureFlagUpdates) => {
                const flattenUpdates = this.flattenUpdates(flagUpdates);
                console.log("Pushing updates", flattenUpdates);
                this.flagUpdates$.next(flattenUpdates);
            });
        }
    }

    isInitialised(): boolean {
        if (this.ldClient) {
            return true;
        }
        return false;
    }

    getFlags(): Observable<ILaunchDarklyFeatureFlags> {
        return this.flags$;
    }

    getFlag(flagId: LaunchDarklyFeatureFlags): Observable<boolean> {
        return this.getFlags().pipe(map((flags) => flags[flagId]));
    }

    private buildUserContext(user: AppUser): ILaunchDarklyUserContext {
        const name = this.getUserName(user);
        const anonymous = user?.id ? false : true;
        const role = user?.globalRole;
        return { key: user?.id || "anonymous", name, anonymous, role };
    }

    private getUserName(user: AppUser): string {
        const userDetailsHasName = user.details?.givenName && user.details?.familyName;
        const name = userDetailsHasName ? `${user.details.givenName} ${user.details.familyName}` : user.name;
        return name || "anonymous";
    }

    private flattenUpdates(flagUpdates: ILaunchDarklyFeatureFlagUpdates): ILaunchDarklyFeatureFlags {
        const updatedFlags = {};
        for (const key in flagUpdates) {
            updatedFlags[key] = flagUpdates[key].current;
        }

        return updatedFlags;
    }

    private setFlags(): void {
        const flags = this.ldClient.allFlags();
        console.log("Flags initialized", flags);
        this.getFlagUpdates(flags);
    }

    private getFlagUpdates(flags: LDFlagSet): void {
        this.flagUpdatesSub = this.flagUpdates$
            .pipe(
                startWith(flags),
                scan((acc, curr) => ({
                    ...acc,
                    ...curr,
                })),
                shareReplay(1),
            )
            .subscribe((flags) => this.flags$.next(flags));
    }
}
