import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { ActorId, CardReply, CardStatus, IThread, IThreadCard, Role } from "@visoryplatform/threads";
import { environmentCommon } from "../../../environment/environment.common";
import { QuillViewComponent } from "ngx-quill";
import { FormControl } from "@angular/forms";
import { ThreadCardService } from "../../services/thread-card.service";
import { IReplyUi } from "../../interfaces/IReplyUi";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "../../../feature-flags";
import { ForwardMessageModalComponent } from "../forward-message/forward-message-modal.component";
import { filter, map, shareReplay, switchMap } from "rxjs/operators";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { PermissionService } from "../../services/permissions.service";
import { combineLatest, Observable, ReplaySubject } from "rxjs";
import { AuthService } from "../../../findex-auth";

@Component({
    selector: "reply",
    templateUrl: "./reply.component.html",
    styleUrls: ["./reply.component.scss"],
})
export class ReplyComponent implements OnInit, OnChanges, OnDestroy {
    @Input() thread: IThread;
    @Input() reply: IReplyUi;
    @Input() userId: string;
    @Input() card: IThreadCard;
    @Input() readonly?: boolean;
    @Input() isUnread: boolean;

    @Output() replyLoading = new EventEmitter<boolean>();

    @ViewChild("replyMessage") replyMessage: QuillViewComponent;

    readonly FEATURE_FLAGS = LaunchDarklyFeatureFlags;

    CardStatus = CardStatus;
    QUILL_MAX_HEIGHT = environmentCommon.quillConfig.maxHeight.reply;
    quillStyles = environmentCommon.quillConfig.styling;
    editReplyMessage = new FormControl<string>("");
    editingReply = false;
    expandCardView = false;
    loading: boolean;
    quillError = false;
    showSecondaryAvatar: boolean;
    showSeeMore = false;
    canEditReplySource = new ReplaySubject<boolean>(1);
    replySource = new ReplaySubject<CardReply>(1);
    canEditReply$ = this.canEditReplySource.asObservable();
    reply$ = this.replySource.asObservable();
    canForwardReply$: Observable<boolean>;
    enableContextMenu$: Observable<boolean>;

    private readonly resizeObservable: ResizeObserver;

    constructor(
        private cardService: ThreadCardService,
        private dialog: MatDialog,
        private authService: AuthService,
        private permissionService: PermissionService,
        private featureFlagService: FeatureFlagService,
    ) {
        this.resizeObservable = new ResizeObserver(() => this.toggleSeeMoreButton());
    }

    ngOnInit(): void {
        const user$ = this.authService.getUser().pipe(filter((user) => !!user));
        const role$ = user$.pipe(map((user) => user.globalRole));

        this.canForwardReply$ = role$.pipe(
            switchMap((role) => this.canForwardCardReply(role)),
            shareReplay(1),
        );

        this.enableContextMenu$ = combineLatest([this.canEditReply$, this.canForwardReply$, this.reply$]).pipe(
            map(([canEditReply, canForwardReply, reply]) => {
                const systemCard = reply.actor === ActorId.System;
                return (canEditReply || canForwardReply) && !systemCard;
            }),
        );
    }

    observeQuill(): void {
        this.resizeObservable.observe(this.replyMessage?.quillEditor?.root);
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { reply, readonly, userId } = changes;

        if (reply && this.reply) {
            this.showSecondaryAvatar = this.isClient(this.reply.actor);
            this.replySource.next(this.reply);
        }

        if ((reply || readonly || userId) && this.reply && this.userId) {
            const canEditReply = this.canEditCardReply(this.reply, this.readonly, this.userId);
            this.canEditReplySource.next(canEditReply);
        }
    }

    ngOnDestroy(): void {
        if (this.resizeObservable) {
            this.resizeObservable.disconnect();
        }

        if (this.loading) {
            this.replyLoading.emit(false);
        }
    }

    toggleSeeMoreButton(): void {
        //Measures the height of the quill editor and triggers 'see more' if longer than our max height
        this.showSeeMore = this.replyMessage?.quillEditor?.root?.offsetHeight >= this.QUILL_MAX_HEIGHT;
    }

    onUserInput(message: FormControl<string>): void {
        this.editReplyMessage = message;
    }

    async updateReply(reply: CardReply, message: FormControl) {
        this.loading = true;
        this.replyLoading.emit(this.loading);

        await this.cardService
            .updateReplyCard(this.thread.id, this.card.id, reply.id, reply.eventKey, message.value)
            .toPromise();
        reply.message = message.value;
        reply.status = CardStatus.Edited;

        this.editReplyMessage.setValue("");
        this.editingReply = false;

        this.loading = false;
        this.replyLoading.emit(this.loading);
    }

    async forwardReply(_reply: CardReply): Promise<void> {
        const config = {
            disableClose: false,
            backdropClass: "modal-backdrop",
            panelClass: ["threads-sidebar", "mat-dialog-no-styling"],
            closeOnNavigation: true,
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
            height: "100vh",
            data: {
                thread: this.thread,
                card: this.card,
                reply: this.reply,
            },
        };

        this.dialog
            .open(ForwardMessageModalComponent, config)
            .afterClosed()
            .pipe(filter((result) => !!result))
            .subscribe();
    }

    async deleteReply(reply: CardReply): Promise<void> {
        this.loading = true;
        this.replyLoading.emit(this.loading);

        await this.cardService.deleteReplyCard(this.thread.id, this.card.id, reply.id, reply.eventKey).toPromise();
        reply.status = CardStatus.Removed;

        this.loading = false;
        this.replyLoading.emit(this.loading);
    }

    private isClient(actorId: string): boolean {
        try {
            const role = this.thread.participants.find((user) => user.id === actorId).role;
            return role === Role.Client || role === null;
        } catch (e) {
            return false;
        }
    }

    private canEditCardReply(reply: CardReply, readonly: boolean, userId: string): boolean {
        const createdByUser = reply.actor === userId;
        const isRemoved = reply.status === CardStatus.Removed;
        return createdByUser && !isRemoved && !readonly;
    }

    private canForwardCardReply(role: Role): Observable<boolean> {
        const forwardFlag = LaunchDarklyFeatureFlags.EnableForwardingMessageOrReply;
        const canForwardCardFlag$ = this.featureFlagService.getFlag(forwardFlag);
        const permission$ = this.permissionService.checkPermissions(role, "ForwardCard");

        return combineLatest([canForwardCardFlag$, permission$]).pipe(map(([flag, permission]) => flag && permission));
    }
}
