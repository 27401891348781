import { WorkflowConfigTokens, WorkflowTokenTypes } from "@visoryplatform/threads";
import { payrunFrequecyTokens, payrunWorkflowRFITokens } from "./payrun-tokens.constants";

import { basFrequencyTokens } from "./bas-tokens.constants";
import { monthEndRoleTokens } from "./month-end-tokens.constants";

export const workflowConfigTokens: WorkflowConfigTokens = {
    [WorkflowTokenTypes.Payrun]: [
        { controlLabel: "Payment method", tokens: payrunWorkflowRFITokens },
        { controlLabel: "Frequency", tokens: payrunFrequecyTokens },
    ],
    [WorkflowTokenTypes.MonthEnd]: [{ controlLabel: "Assignee", tokens: monthEndRoleTokens, mandatory: true }],
    [WorkflowTokenTypes.Bas]: [{ controlLabel: "Frequency", tokens: basFrequencyTokens }],
};
