<loader-icon
    *ngIf="showLoader"
    color="light"
></loader-icon>
<button
    *ngIf="steps[currentStepId].allowNavigateBack"
    type="button"
    class="fx-onboarding-modal-back"
    (click)="goBack()"
>
    <i class="las la-angle-left"></i>
</button>
<div class="onboarding-completion">
    <ng-template onboardingStep></ng-template>
</div>
<pagination
    [steps]="stepNames"
    [activeStep]="currentStepName"
    (navigateStep)="navigateStep($event)"
></pagination>
