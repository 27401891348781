import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { PortalService } from "../../../shared/services/portal.service";
import { ThreadUpdateService } from "../../../shared/services/thread-update-service";
import { EMPTY, Observable, combineLatest, of } from "rxjs";
import { map, distinctUntilChanged, filter, switchMap, shareReplay, catchError } from "rxjs/operators";
import { ITimeline, Role } from "@visoryplatform/threads";
import { Loader } from "../../../shared/services/loader";
import { ErrorService } from "../../../shared/services/error.service";
import { ButtonType } from "../../../shared/components/error/error.component";

@Injectable({
    providedIn: "root",
})
export class ThreadRouteService {
    constructor(
        private authService: AuthService,
        private portalService: PortalService,
        private threadUpdateService: ThreadUpdateService,
        private errorService: ErrorService,
    ) {}

    getThread(route: ActivatedRoute, loader: Loader): Observable<ITimeline> {
        return this.getCurrentThread(route, loader);
    }

    getRole(route: ActivatedRoute, loader: Loader): Observable<Role> {
        const user$ = this.authService.getValidUser();
        const userId$ = user$.pipe(map((user) => user.id));
        const globalRole$ = user$.pipe(map((user) => user.globalRole));

        const thread$ = this.getCurrentThread(route, loader);
        return combineLatest([thread$, userId$]).pipe(
            switchMap(([thread, userId]) => {
                const userParticipant = thread?.participants?.find((participant) => participant.id === userId);
                if (userParticipant?.role) {
                    return of(userParticipant.role);
                } else {
                    return globalRole$;
                }
            }),
            distinctUntilChanged(),
        );
    }

    private getCurrentThread(route: ActivatedRoute, loader: Loader): Observable<ITimeline> {
        const threadRouteId$ = route.params.pipe(
            filter((params) => params?.threadId),
            map((params) => params.threadId),
            distinctUntilChanged(),
            shareReplay(1),
        );

        const currentThread$ = threadRouteId$.pipe(
            switchMap((threadIdInRoute) => loader.wrap(this.portalService.getThreadListById(threadIdInRoute))),
            switchMap((thread) => this.threadUpdateService.getUpdatesByThread(thread)),
            distinctUntilChanged(),
            shareReplay(1),
            catchError(() => {
                this.errorService.showErrorModal(
                    "Workflow not found",
                    "No workflow found for that ID",
                    ButtonType.GO_BACK,
                    true,
                    "/timelines",
                );
                return EMPTY;
            }),
        );

        return currentThread$;
    }
}
