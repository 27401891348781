import { Injectable, Injector } from "@angular/core";
import { IPluginFactory } from "projects/portal-modules/src/lib/plugins";
import { Libraries, RouteExtension, TaskAction } from "projects/portal-modules/src/lib/plugins/services/Libraries";
import { Account, TRANSFORMER_CARD_TYPE } from "@visoryplatform/threads";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";
import { TransformerCardComponent } from "./components/transformer-card/transformer-card.component";
import { TransformerActionComponent } from "./components/transformer-action/transformer-action.component";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { CardResources } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { IRequestModalData } from "../vault/components/request/interfaces/IRequestModalData";
import { CreateTransformerComponent } from "./components/create-transformer.component";
import { AccountTransformersComponent } from "./components/account-transformers/account-transformers.component";
import { Observable, of } from "rxjs";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";
import { AppUser, AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { filter, switchMap } from "rxjs/operators";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags";
import { TransformerActions } from "./types/TransformerActions";

async function viewTransformActionFactory(
    cardResources: Partial<CardResources>,
    injector: Injector,
    _options: void,
): Promise<boolean> {
    const matDialog = injector.get(MatDialog);
    const { thread$, card$, state$, role, replies$ } = cardResources;
    const data = { state$, thread$, replies$, card$, role, readonly: false, focusReplyInput: false };

    const config = {
        data,
        panelClass: ["centered-modal"],
        disableClose: true,
        width: "761px",
        autoFocus: false,
    };

    return matDialog
        .open<any, IRequestModalData, boolean>(TransformerActionComponent, config)
        .afterClosed()
        .toPromise();
}

@Injectable()
export class TransformersPlugin implements IPluginFactory {
    readonly id = "TransformsPlugin";
    readonly gaEvents = GA_EVENTS;

    private featureEnabled$: Observable<boolean>;

    constructor(
        private libraries: Libraries,
        private authService: AuthService,
        private permissionService: PermissionService,
        private featureFlagService: FeatureFlagService,
    ) {
        this.featureEnabled$ = this.featureFlagService.getFlag(LaunchDarklyFeatureFlags.EnableTransformersCard);

        this.registerExtensions();
    }

    private registerExtensions(): void {
        const viewTransformerResultAction: TaskAction<boolean> = {
            analyticsEvents: [GA_EVENTS.TRANSFORMER_VIEW],
            cardTypes: [TRANSFORMER_CARD_TYPE],
            action: viewTransformActionFactory,
            buttonLabel: "View",
            statusIcon: "las la-check-square",
        };

        this.libraries.cardViews.register(TRANSFORMER_CARD_TYPE, TransformerCardComponent);
        this.libraries.taskActions.register(TransformerActions.ViewTransformResults, viewTransformerResultAction);
        this.libraries.createCard.register(TRANSFORMER_CARD_TYPE, {
            title: "Deep check",
            tooltipMessage: "Create a Xero file check.",
            analyticsEvent: this.gaEvents.APP_CREATE_PAYMENT_CARD,
            permission: ["CreateTransformerCard"],
            featureFlags: [LaunchDarklyFeatureFlags.EnableTransformersCard],
            icon: "la-check-square",
            disableInternalCreation: true,
            componentRef: CreateTransformerComponent,
            config: {
                panelClass: ["centered-modal"],
                disableClose: false,
            },
        });
        const accountTransformers: RouteExtension<Account> = {
            label: "Quality checks",
            icon: "ai-icon",
            showExtension: (account) => this.showExtension(account),
            route: {
                path: "transformers",
                component: AccountTransformersComponent,
            },
        };

        this.libraries.accountRoutes.register("transfomers", accountTransformers);
    }

    private showExtension(account: Account): Observable<boolean> {
        if (!account) {
            return of(false);
        }

        const userHasPermission$ = this.authService.getUser().pipe(
            filter((user) => !!user),
            switchMap((user) => this.userHasPermission(user)),
        );

        return this.featureEnabled$.pipe(
            switchMap((enabled) => {
                if (!enabled) {
                    return of(false);
                }

                return userHasPermission$;
            }),
        );
    }

    private userHasPermission(user: AppUser): Observable<boolean> {
        return this.permissionService.checkPermissions(user.globalRole, "ConfigureAccountTransformers");
    }
}
