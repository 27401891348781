<div class="fx-onboarding fx-onboarding-bg-password">
    <div class="fx-onboarding-container">
        <div class="fx-onboarding-content">
            <portal-brand type="onboarding"></portal-brand>
            <h1
                class="fx-onboarding-title"
                *ngIf="textResource.createPasswordTitle"
            >
                {{ textResource.createPasswordTitle }}
            </h1>
            <p
                class="fx-onboarding-body"
                *ngIf="textResource.createPasswordDescription"
            >
                {{ textResource.createPasswordDescription }}
            </p>
            <form
                class="fx-onboarding-form fx-form fx-form--dark client-profile-form"
                [formGroup]="form"
                (submit)="updateParentModel()"
            >
                <div
                    class="fx-form-group"
                    *ngIf="errorMessage"
                >
                    <div class="fx-form-validation-panel">
                        <div class="fx-form-validation-panel-item fx-onboarding-form-error">{{ errorMessage }}</div>
                    </div>
                </div>
                <div class="fx-form-group">
                    <label
                        class="fx-form-label"
                        for="password"
                        >Create password</label
                    >
                    <div class="fx-form-control">
                        <input
                            formControlName="password"
                            class="fx-form-input"
                            name="Password"
                            placeholder="Password"
                            type="password"
                            togglePassword
                            [showClass]="'fx-form-icon fx-form-icon--right las la-eye-slash'"
                            [hideClass]="'fx-form-icon fx-form-icon--right las la-eye'"
                            [ngClass]="{
                                'fx-form-input--error':
                                    (form.getError('matchingFields') === false || !form.controls['password'].valid) &&
                                    form.controls['password'].touched
                            }"
                        />
                    </div>
                    <div
                        class="fx-form-validation"
                        *ngIf="
                            (form.getError('matchingFields') === false || !form.controls['password'].valid) &&
                            form.controls['password'].touched
                        "
                    >
                        {{ getPasswordError("Password", form.getError("matchingFields")) }}
                    </div>
                </div>
                <div class="fx-form-group">
                    <label
                        class="fx-form-label"
                        for="confirmPassword"
                        >Confirm password</label
                    >
                    <div class="fx-form-control">
                        <input
                            formControlName="confirmPassword"
                            class="fx-form-input"
                            name="ConfirmPassword"
                            placeholder="Confirm Password"
                            type="password"
                            togglePassword
                            [showClass]="'fx-form-icon fx-form-icon--right las la-eye-slash'"
                            [hideClass]="'fx-form-icon fx-form-icon--right las la-eye'"
                            [ngClass]="{
                                'fx-form-input--error':
                                    (form.getError('matchingFields') === false ||
                                        !form.controls['confirmPassword'].valid) &&
                                    form.controls['confirmPassword'].touched
                            }"
                        />
                    </div>
                    <div
                        class="fx-form-validation"
                        *ngIf="
                            (form.getError('matchingFields') === false || !form.controls['confirmPassword'].valid) &&
                            form.controls['confirmPassword'].touched
                        "
                    >
                        {{ getPasswordError("ConfirmPassword", form.getError("matchingFields")) }}
                    </div>
                </div>
                <div class="fx-form-group">
                    <button
                        class="fx-btn fx-btn--full-width"
                        [ngClass]="['fx-btn--primary' + (theme.formSubmitActionOutlined ? '-outlined' : '')]"
                        [disabled]="!form.valid"
                        analyticsClick="onboarding_createprofile"
                        type="submit"
                    >
                        {{ textResource.createPasswordAction || textResource.default.createPasswordAction }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
