import { Injectable } from "@angular/core";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { Observable, combineLatest, of } from "rxjs";
import { map, distinctUntilChanged, filter, switchMap, shareReplay, take } from "rxjs/operators";
import { ITimeline, Role } from "@visoryplatform/threads";
import { ThreadUpdateService } from "projects/portal-modules/src/lib/shared/services/thread-update-service";
import { AccountRouteService } from "projects/portal-modules/src/lib/account/services/account-route.service";
import { InternalChatService } from "../../services/internal-chat.service";
import { Loader } from "projects/portal-modules/src/lib/shared/services/loader";

@Injectable({
    providedIn: "root",
})
export class InternalChatRouteService {
    constructor(
        private authService: AuthService,
        private threadUpdateService: ThreadUpdateService,
        private internalChatService: InternalChatService,
    ) {}

    getThread(accountRouteService: AccountRouteService, loader: Loader): Observable<ITimeline> {
        return this.getCurrentThread(accountRouteService, loader);
    }

    getRole(accountRoute: AccountRouteService, loader: Loader): Observable<Role> {
        const user$ = this.authService.getValidUser();
        const userId$ = user$.pipe(map((user) => user.id));
        const globalRole$ = user$.pipe(map((user) => user.globalRole));
        const thread$ = this.getCurrentThread(accountRoute, loader);

        return combineLatest([thread$, userId$]).pipe(
            switchMap(([thread, userId]) => {
                const userParticipant = thread?.participants?.find((participant) => participant.id === userId);
                if (userParticipant?.role) {
                    return of(userParticipant.role);
                } else {
                    return globalRole$;
                }
            }),
            distinctUntilChanged(),
        );
    }

    private getCurrentThread(accountRouteService: AccountRouteService, loader: Loader): Observable<ITimeline> {
        const account$ = accountRouteService.getAccount().pipe(take(1));

        return account$.pipe(
            map((account) => account.id),
            filter((accountId) => !!accountId),
            switchMap((accountId) => loader.wrap(this.internalChatService.getInternalChat(accountId))),
            switchMap((thread) => this.threadUpdateService.getUpdatesByThread(thread)),
            shareReplay(1),
            distinctUntilChanged(),
        );
    }
}
