<ng-container *ngIf="userId$ | async as userId">
    <app-card
        *ngIf="card.status === cardStatuses.Removed || card.status === cardStatuses.Deleted"
        [lineCard]="card.status === cardStatuses.Removed || card.status === cardStatuses.Deleted"
        description="This message has been removed."
        [timestamp]="card.modifiedAt"
        [conversation]="false"
    >
    </app-card>

    <app-card
        *ngIf="thread && card && card.status !== cardStatuses.Deleted && card.status !== cardStatuses.Removed"
        class="thread-card"
        [ngClass]="{ 'thread-card--internal': isInternal }"
        [title]="title"
        [name]="card.createdBy | userToName | async"
        [image]="card.createdBy | profilePicture | async"
        [timestamp]="card.createdAt"
        [description]="card.description"
        [invertTheme]="invertTheme"
        [edited]="edited"
        [edit]="edit"
        [align]="invertTheme ? 'right' : 'left'"
        (save)="save.emit($event)"
        [loading]="loading"
        [attr.data-id]="card.id"
        [showDescription]="showDescription"
        [showFullDescription]="(edit | async) || !(thread | isThreadActive)"
        [isUnread]="(isUnseenByCurrentUser$ | async) || (isUnreadByCurrentUser$ | async)"
        [notificationChannel]="'activity/threads/' + thread.id + '/cards/' + card.id + '/cardContent'"
        [enableMarkAsSeen]="isUnseenByCurrentUser$ | async"
        [enableMarkAsResolved]="isUnreadByCurrentUser$ | async"
        [markAsSeen]="{ card, thread, participantId: userId }"
        [isInternal]="isInternal"
    >
        <p
            class="thread-card-error"
            *ngIf="!loading && card?.status === cardStatuses.Disabled"
        >
            Tasks on this card have been cancelled.
        </p>

        <ng-container card-actions>
            <ng-content select="[card-actions]"></ng-content>
        </ng-container>

        <ng-container card-loader>
            <ng-content select="[card-loader]"></ng-content>
        </ng-container>

        <ng-content *ngIf="!loading"></ng-content>

        <ng-container *ngIf="cardVisible$ | async">
            <div
                *ngIf="card.disableEmails"
                class="thread-card-reply-status"
            >
                <card-seen-by
                    [cardSeenByParticipants]="cardSeenBy$ | async"
                    [threadParticipants]="thread.participants"
                    [currentUser]="userId"
                    [card]="card"
                ></card-seen-by>
            </div>

            <div
                *ngIf="!card.disableEmails"
                class="thread-card-reply-status"
            >
                <card-read-status [readStatus]="cardContentReadStatus$ | async"></card-read-status>
            </div>
        </ng-container>

        <section
            *ngIf="inCardReplies && card.type !== cardTypes.VaultInstructionsRequest; else inModalReplies"
            card-replies
        >
            <thread-card-replies
                [replies]="replies"
                [card]="card"
                [thread]="thread"
                [userId]="userId"
                [isInternal]="isInternal"
            ></thread-card-replies>
        </section>

        <ng-template #inModalReplies>
            <div
                *ngIf="card.type !== cardTypes.VaultInstructionsRequest"
                class="thread-card-replies"
            >
                <thread-card-replies-list
                    [replies]="replies"
                    [thread]="thread"
                    [card]="card"
                    [userId]="userId"
                ></thread-card-replies-list>

                <reply-compose
                    [cardType]="card?.type"
                    [inlineCompose]="false"
                    (click)="openRepliesInModal.emit()"
                >
                </reply-compose>
            </div>
        </ng-template>
    </app-card>
</ng-container>
