import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "notificationIcon" })
export class NotificationIconPipe implements PipeTransform {
    transform(icon: string): string {
        switch (icon) {
            case "calendar":
                return "la-calendar";
            case "payment":
                return "la-money-check-alt";
            case "message":
                return "la-comment";
            default:
                return "la-comment-dots";
        }
    }
}
