<loader-icon
    *ngIf="loader.counter"
    color="light"
></loader-icon>
<route-drawer>
    <fx-layout [gutters]="false">
        <fx-layout-column
            [sizes]="{ mobile: 24 }"
            *ngIf="currentThread$ | async; let thread"
        >
            <ng-container *ngIf="currentRole$ | async; let role">
                <div
                    *ngIf="extension$ | async as extension; else activity"
                    class="fx-threads-route"
                >
                    <router-outlet></router-outlet>
                </div>

                <ng-template #activity>
                    <div
                        #threadsContainer
                        class="fx-threads-messages"
                        invertScroll
                    >
                        <thread
                            [ngClass]="{ loading: loader.counter }"
                            [threadId]="thread.id"
                            [thread$]="currentThread$"
                            [role]="role"
                            [routeToCardId]="cardId$ | async"
                            [excludeCardTypes]="['thread']"
                            (loadCardComplete)="scrollToBottom()"
                        ></thread>
                    </div>

                    <div
                        class="fx-threads-new-message"
                        *ngIf="!loader.counter"
                    >
                        <create-card
                            *ngIf="thread | isThreadActive"
                            [showLoader]="!!createLoader.counter"
                            [role]="role"
                            [globalRole]="globalRole$ | async"
                            [restrictCardsToInternal]="thread.restrictCardsToInternal"
                            (newCard)="addCard($event, thread)"
                        ></create-card>
                    </div>
                </ng-template>
            </ng-container>
        </fx-layout-column>
    </fx-layout>
</route-drawer>
