import { Component, Input } from "@angular/core";
import { environmentCommon } from "../../../environment/environment.common";

@Component({
    selector: "forward-message-preview",
    templateUrl: "./forward-message-preview.component.html",
    styleUrls: ["./forward-message-preview.component.scss"],
})
export class ForwardMessagePreviewComponent {
    @Input() name: string;
    @Input() image: string;
    @Input() message: string;
    @Input() timestamp: string;

    quillStyles = environmentCommon.quillConfig.styling;
}
