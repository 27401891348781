<header class="fx-header">
    <div class="fx-header-left-items">
        <a
            class="fx-header-menu-toggle"
            (click)="menu.emit(!layoutOpen); $event.stopPropagation()"
            *ngIf="showMenuToggle"
        >
            <i
                class="las la-bars"
                [ngClass]="{ 'la-times': layoutOpen }"
            ></i>
        </a>
        <portal-brand type="menu"></portal-brand>

        <span
            class="fx-header-uat-indicator"
            *ngIf="stage === 'uat'"
            >User Acceptance Test</span
        >
        <span
            class="fx-header-uat-indicator"
            *ngIf="stage === 'qa'"
            >Quality Assurance</span
        >
    </div>

    <div class="fx-header-right-items">
        <ng-content></ng-content>
    </div>
</header>
