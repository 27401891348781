<div
    class="business-industry-option"
    (click)="toggleSelected()"
>
    <div
        class="business-industry-option-checkmark"
        [ngClass]="{ selected: selected }"
    >
        <i class="las la-check"></i>
    </div>
    <div
        class="business-industry-option-name"
        [ngClass]="{ selected: selected }"
    >
        {{ name }}
    </div>
</div>
