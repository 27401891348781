import { IThread, Role } from "@visoryplatform/threads";
import { VaultService } from "@visoryplatform/vault";
import { HttpEventType } from "@angular/common/http";
import { Component, Inject, OnDestroy } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { IVaultGroup } from "../../interfaces/IVaultGroup";
import { IVaultItem } from "../../interfaces/IVaultItem";
import { UploadCancelModalComponent } from "./upload-cancel-modal/upload-cancel-modal.component";
import { Observable, Subscription } from "rxjs";
import { IVaultItemFile } from "../../interfaces/IVaultItemFile";
import { finalize, ignoreElements, tap } from "rxjs/operators";
import { CompleteRequestModalComponent, CompleteRfiData } from "./complete-rfi-modal/complete-request-modal.component";
import { IVaultState } from "../../interfaces/IVaultState";
import { GA_EVENTS } from "projects/portal-modules/src/lib/analytics";

export interface IData {
    state$: Observable<IVaultState>;
    thread: IThread;
    role: Role;
}

@Component({
    selector: "app-upload",
    templateUrl: "./upload.component.html",
    styleUrls: ["./upload.component.scss"],
})
export class UploadComponent implements OnDestroy {
    readonly gaEvents = GA_EVENTS;

    roles = Role;
    role: Role;
    closed: boolean;
    vaultGroups: IVaultGroup[];
    uploadCount: number;
    thread: IThread;

    private groupSub: Subscription;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: IData,
        private dialogRef: MatDialogRef<UploadComponent>,
        private vaultService: VaultService,
        public dialog: MatDialog,
    ) {
        if (!data) {
            return;
        }

        const { thread, state$, role } = data;
        this.role = role;

        this.groupSub = state$.subscribe(({ groups, closed }) => {
            this.closed = closed;
            groups.forEach((group) => group.items.sort((a, b) => this.sortDisplayName(a, b)));
            this.vaultGroups = groups.sort((a, b) => this.sortDisplayName(a, b));
        });
        this.thread = thread;
    }

    ngOnDestroy() {
        if (this.groupSub) {
            this.groupSub.unsubscribe();
        }

        if (this.vaultGroups) {
            this.cancelUploads(this.vaultGroups);
        }
    }

    async deleteFile(group: IVaultGroup, item: IVaultItem, file: IVaultItemFile) {
        group.loading = true;
        file.deleting = true;

        try {
            await this.vaultService.removeRfiUpload(item.vaultId, item.fileId, file.filename).toPromise();
            const fileIndex = item.files.indexOf(file);
            item.files.splice(fileIndex, 1);
        } finally {
            file.deleting = false;
            group.loading = false;
        }
    }

    uploadFile(group: IVaultGroup, item: IVaultItem, file: File): void {
        group.loading = true;

        const vaultFile: IVaultItemFile = {
            filename: file.name,
            progress: 2,
        };

        vaultFile.uploadSub = this.vaultService
            .uploadRfi(item.vaultId, item.fileId, file)
            .pipe(
                tap((event) => {
                    if (event && event.type === HttpEventType.UploadProgress) {
                        vaultFile.progress = Number((event.loaded / event.total) * 98);
                    }
                }),
                ignoreElements(),
                finalize(() => (vaultFile.progress = 99)),
            )
            .subscribe(
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                () => {},
                (up) => {
                    throw up;
                }, //Don't want to handle it, just want the complete handler
                () => {
                    vaultFile.uploadSub.unsubscribe();
                    vaultFile.uploadSub = null;
                },
            );

        const existingFile = item.files.findIndex((existing) => existing.filename === file.name);
        if (existingFile > -1) {
            item.files.splice(existingFile, 1);
        }

        item.files.push(vaultFile);
    }

    async openFile(item: IVaultItem, file: IVaultItemFile) {
        file.downloading = true;

        try {
            const { filename } = file;
            const { vaultId, fileId } = item;

            const downloadWindow = window.open("", "_self");
            const downloadUrl = await this.vaultService.getDownloadUrl(vaultId, fileId, filename).toPromise();
            downloadWindow.location.href = downloadUrl;
        } finally {
            file.downloading = false;
        }
    }

    async completeRfi() {
        const vaultGroup = this.vaultGroups.slice(0, 1).pop();
        if (!vaultGroup) {
            this.closeModal();
        }

        const vaultFile = vaultGroup.items.slice(0, 1).pop();
        if (!vaultFile) {
            this.closeModal();
        }

        const { vaultId } = vaultFile;
        const data: CompleteRfiData = { vaultId };
        const complete = await this.dialog
            .open(CompleteRequestModalComponent, { data, panelClass: "centered-modal" })
            .afterClosed()
            .toPromise();

        if (complete) {
            this.dialogRef.close();
        }
    }

    closeModal() {
        if (!this.uploadInProgress(this.vaultGroups)) {
            return this.dialogRef.close();
        }

        const dialogRef = this.dialog.open(UploadCancelModalComponent, {
            data: {},
            width: "274px",
        });

        dialogRef.afterClosed().subscribe((data) => {
            if (!data) {
                return;
            }

            this.cancelUploads(this.vaultGroups);
            this.dialogRef.close();
        });
    }

    trackItem(_index: number, item: IVaultItem) {
        return item.fileId;
    }

    trackGroup(_index: number, group: IVaultGroup) {
        return group.displayName;
    }

    uploadInProgress(vaultGroups: IVaultGroup[]): boolean {
        for (const group of vaultGroups) {
            for (const item of group.items) {
                for (const file of item.files) {
                    if (file.uploadSub) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    private sortDisplayName(a: { displayName: string }, b: { displayName: string }): number {
        return a.displayName.localeCompare(b.displayName);
    }

    private cancelUploads(vaultGroups: IVaultGroup[]) {
        for (const group of vaultGroups) {
            for (const item of group.items) {
                for (const file of item.files) {
                    if (file.uploadSub) {
                        file.uploadSub.unsubscribe();
                        file.uploadSub = null;
                    }
                }
            }
        }
    }
}
