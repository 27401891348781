<div class="reply">
    <ng-container *ngIf="!editingReply">
        <fx-avatar
            [name]="reply.actor | userToName | async"
            [image]="reply.actor | profilePicture : showSecondaryAvatar | async"
            size="medium"
            [secondary]="showSecondaryAvatar"
        >
        </fx-avatar>

        <div
            [ngSwitch]="reply.status === CardStatus.Removed"
            class="reply-body"
        >
            <div class="reply-body-header">
                <div class="reply-body-header-name">{{ reply.actor | userToName | async }}</div>
                <div>
                    <div class="reply-body-header-control-panel">
                        <!-- TODO swap to TaigaUI Pill once Taiga is introduced to project-->
                        <div
                            class="reply-body-header-control-panel-status-badge"
                            [unreadStatus]="isUnread"
                        >
                            <fx-badge
                                label="Unread"
                                type="info"
                            ></fx-badge>
                        </div>

                        <div
                            *ngIf="reply.status === CardStatus.Edited"
                            class="reply-body-header-control-panel-edited"
                        >
                            Edited
                        </div>

                        <div class="reply-body-header-control-panel-timestamp">
                            {{ reply.timestamp | date : "dd/MM/yy, h:mm a" }}
                        </div>
                    </div>
                </div>
            </div>

            <div
                *ngSwitchDefault
                class="reply-body-message"
            >
                <read-more [showFullDescription]="!(thread | isThreadActive)">
                    <quill-view
                        [style]="quillStyles"
                        [content]="reply.message | allowWhitelistLinks"
                        aria-label="message"
                    >
                    </quill-view>
                </read-more>
            </div>

            <div
                *ngSwitchCase="true"
                class="reply-body-details-removed"
            >
                This message has been removed.
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="editingReply">
        <div class="reply-edit">
            <quill-editor-wrapper
                (userInputEmitter)="onUserInput($event)"
                (error)="quillError = $event"
                [message]="editReplyMessage"
                [inline]="true"
            >
            </quill-editor-wrapper>

            <div class="reply-edit-buttons">
                <reply-buttons
                    (discardOutput)="editingReply = false; editReplyMessage.setValue(reply.message)"
                    (submitOutput)="updateReply(reply, editReplyMessage)"
                    [submitDisabled]="!editReplyMessage.value || editReplyMessage.value === reply.message || loading"
                >
                </reply-buttons>
            </div>
        </div>
    </ng-container>

    <div
        *ngIf="(enableContextMenu$ | async) && !editingReply"
        class="reply-menu"
    >
        <fx-context-menu
            #menu
            [gutter]="false"
        >
            <a
                (click)="editReplyMessage.setValue(reply.message); editingReply = true"
                *ngIf="canEditReply$ | async"
                >Edit</a
            >
            <a
                *ngIf="canForwardReply$ | async"
                (click)="forwardReply(reply)"
                >Forward</a
            >
            <a
                (click)="deleteReply(reply)"
                *ngIf="canEditReply$ | async"
                >Delete</a
            >
        </fx-context-menu>
    </div>

    <div
        *ngIf="reply.status !== CardStatus.Removed && !editingReply"
        class="reply-read-status"
    >
        <card-read-status [readStatus]="reply?.replyStatus"></card-read-status>
    </div>
</div>
