import { NgModule } from "@angular/core";
import {
    CalendarModule,
    DashboardModule,
    InsightsModule,
    InstructionsModule,
    IntegrationsModule,
    MessagePluginModule,
    NotificationModule,
    PaymentModule,
    PayrollModule,
    TransformersModule,
    MemoriesModule,
    VaultModule,
    VideoChatModule,
    CopilotModule,
    InternalChatModule,
    AssistantModule,
    UserTimelinesModules,
    BillApprovalModule,
} from ".";

const plugins = [
    MessagePluginModule,
    VaultModule,
    CalendarModule,
    PaymentModule,
    PayrollModule,
    InstructionsModule,
    DashboardModule,
    InsightsModule,
    NotificationModule,
    VideoChatModule,
    IntegrationsModule,
    TransformersModule,
    CopilotModule,
    MemoriesModule,
    AssistantModule,
    InternalChatModule,
    UserTimelinesModules,
    BillApprovalModule,
];

@NgModule({
    imports: [...plugins],
    exports: [...plugins],
})
export class SharedPluginsModule {}
