import { Component, OnInit } from "@angular/core";
import { AuthService } from "./../../../../portal-modules/src/lib/findex-auth";
import { Observable, Subscription } from "rxjs";
import { filter, map } from "rxjs/operators";
import { ICardTaskDetail, Role } from "@visoryplatform/threads";
import { GreetingFunction } from "../../greeting-helper";

@Component({
    selector: "dashboard-greeting-banner",
    templateUrl: "./dashboard-greeting-banner.component.html",
    styleUrls: ["./dashboard-greeting-banner.component.scss"],
})
export class DashboardGreetingBannerComponent implements OnInit {
    role: Role;
    greeting$: Observable<string>;
    tasks$: Observable<ICardTaskDetail[]>;
    subscriptions: Subscription[] = [];

    constructor(private authService: AuthService) {}

    ngOnInit(): void {
        this.greeting$ = this.authService.getUser().pipe(
            filter((user) => !!user),
            map((user) => GreetingFunction.userToGreeting(user)),
        );
    }
}
