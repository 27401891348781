export enum LaunchDarklyFeatureFlags {
    EnableInternalChat = "enable-internal-chat",
    EnableMemories = "enable-memories",
    EnableTransactionQueries = "enable-transaction-queries",
    EnableCustomerOnboarding = "enable-customer-onboarding",
    EnableDeplhiAlerts = "enable-delphi-alerts",
    EnableTransformersCard = "enable-transformer-card-type",
    EnableUniversalInsights = "enable-universal-insights",
    EnableWorkflowDashboardDueDate = "enable-workflow-dashboard-due-date",
    EnableWorkflowDashboardTasks = "enable-workflow-dashboard-tasks",
    EnableWorkflowListAndStepper = "enable-workflow-list-and-stepper",
    EnableAIAssistant = "enable-ai-assistant",
    EnableDelphiBranding = "enable-delphi-branding",
    EnableWorkflowRelevantMemories = "enable-workflow-relevant-memories",
    EnableBulkChangeAssignees = "enable-bulk-change-assignees",
    EnableMonthEndWorkflowWithRoles = "enable-month-end-workflow-with-roles",
    EnableBillApproval = "enable-bills-approval",
    EnableForwardingMessageOrReply = "enable-forwarding-a-message-or-reply",
}
