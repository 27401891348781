import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: "pagination",
    templateUrl: "./pagination.component.html",
    styleUrls: ["./pagination.component.scss"],
})
export class PaginationComponent {
    @Input() steps: string[];
    @Input() activeStep: string;

    @Output() navigateStep = new EventEmitter();
}
