import { Component, Input, OnDestroy } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
    selector: "search",
    templateUrl: "./search.component.html",
    styleUrls: ["./search.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: SearchComponent,
        },
    ],
})
export class SearchComponent implements OnDestroy, ControlValueAccessor {
    @Input() analyticsClickEvent: string;
    @Input() placeholder = "Search";
    @Input() tooltip = "";
    @Input() value: string;

    searchSubscription: Subscription;
    formControl = new FormControl<string>("");

    onChange?: (obj: Partial<string>) => void;
    onTouch?: () => void;

    constructor() {
        this.searchSubscription = this.formControl.valueChanges
            .pipe(debounceTime(500), distinctUntilChanged())
            .subscribe((value) => {
                this.onChange?.(value);
                this.onTouch?.();
            });
    }

    writeValue(value: string): void {
        this.formControl.setValue(value);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    ngOnDestroy(): void {
        this.searchSubscription?.unsubscribe();
    }
}
