import { Component, Output, EventEmitter, Input, OnInit } from "@angular/core";
import { IVaultItem } from "../../interfaces/IVaultItem";
import { Observable, of, AsyncSubject } from "rxjs";
import { VaultService } from "@visoryplatform/vault";
import { HttpClient } from "@angular/common/http";
import { switchMap } from "rxjs/operators";

@Component({
    selector: "data-input",
    templateUrl: "./data-input.component.html",
    styleUrls: ["./data-input.component.scss"],
})
export class DataInputComponent implements OnInit {
    @Output() textFile = new EventEmitter<File>();
    @Input() name: string;
    @Input() item: IVaultItem;

    errorMessage: string;

    defaultValue$: Observable<string>;

    constructor(private vaultService: VaultService, private http: HttpClient) {}

    ngOnInit() {
        if (this.item.files[0]) {
            this.defaultValue$ = this.vaultService
                .getDownloadUrl(this.item.vaultId, this.item.fileId, this.item.files[0].filename)
                .pipe(
                    switchMap((url) => this.http.get(url, { responseType: "blob" })),
                    switchMap((blob: Blob) => {
                        const fileReader = new FileReader();
                        const callbackSubject = new AsyncSubject<string>();
                        fileReader.readAsText(blob);
                        fileReader.onload = () => {
                            callbackSubject.next(fileReader.result as string);
                            callbackSubject.complete();
                        };
                        return callbackSubject.asObservable();
                    }),
                );
        } else {
            this.defaultValue$ = of("");
        }
    }

    outputTextFile(value: string) {
        try {
            if (value && value.trim() !== "") {
                const file = new File([new TextEncoder().encode(value)], `input.txt`);
                this.textFile.emit(file);
                this.errorMessage = null;
            } else {
                this.errorMessage = "Field is required.";
                return;
            }
        } catch (err) {
            console.error(err);
            this.errorMessage = "Could not convert to a file, contact support!";
        }
    }
}
