import { Component, Inject, OnInit } from "@angular/core";
import { OnboardingService, SignUpUserPayload } from "../../services/onboarding.service";
import { AnalyticsService } from "projects/portal-modules/src/lib/analytics";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";

export enum OnboardingStatus {
    INITALIZE = "initialize",
    SIGN_UP = "signUp",
    CREATE_PASSWORD = "createPassword",
    VERIFY_EMAIL = "verifyEmail",
}

@Component({
    selector: "app-onboarding-initial",
    templateUrl: "./onboarding-initial.component.html",
    styleUrls: ["./onboarding-initial.component.scss"],
})
export class OnboardingInitialComponent implements OnInit {
    userDataModel: SignUpUserPayload = {
        givenName: "",
        familyName: "",
        emailAddress: "",
        mobileNumber: "",
        password: "",
    };
    errorMessage = "";

    status: OnboardingStatus = OnboardingStatus.INITALIZE;
    showLoader = false;
    invertLoaderColour: boolean;
    verifyEmailMessage: { message: string; error: boolean };

    readonly ALL_STATUSES = OnboardingStatus;

    // @ts-ignore
    constructor(
        private onboardingService: OnboardingService,
        private authService: AuthService,
        private analyticsService: AnalyticsService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    ngOnInit(): void {
        if (this.environment.featureFlags.signupEnabled) {
            this.status = OnboardingStatus.SIGN_UP;
            this.activatedRoute.queryParams.subscribe((params) => this.setParamDataModel(params));
        } else {
            this.status = OnboardingStatus.INITALIZE;
            window.location.href = this.environment.signupRedirectUrl;
        }
    }

    public updateUserModel(updatedClientModel: SignUpUserPayload) {
        Object.keys(updatedClientModel).map((key) => {
            if (key !== undefined || key !== "") {
                this.userDataModel[key] = updatedClientModel[key];
            }
        });
    }

    async progressState(updatedClientModel: SignUpUserPayload) {
        this.showLoader = true;
        const analyticsPath = this.activatedRoute.snapshot.pathFromRoot
            .map((value) => value.url.map((urlValue) => urlValue.toString()).join("/"))
            .join("/");

        this.updateUserModel(updatedClientModel);
        switch (this.status) {
            case OnboardingStatus.SIGN_UP: {
                this.status = await this.checkSignUpStatus();
                this.analyticsService.pageViewed(analyticsPath);
                break;
            }
            case OnboardingStatus.CREATE_PASSWORD: {
                this.status = await this.completeSignUp();
                this.analyticsService.pageViewed(analyticsPath);
                break;
            }
        }
        this.showLoader = false;
    }

    private async setParamDataModel(params: Params) {
        if (params.emailAddress) {
            this.userDataModel.emailAddress = params.emailAddress;
        }

        if (params.givenName) {
            this.userDataModel.givenName = params.givenName;
        }

        if (params.familyName) {
            this.userDataModel.givenName = params.familyName;
        }
    }

    private async checkSignUpStatus(): Promise<OnboardingStatus> {
        const result = await this.authService.checkSignUpStatus(this.userDataModel.emailAddress);
        if (!result.success) {
            this.errorMessage = result.errorMessage;
            this.recordAnalyticsEvent("signup-error");
            return this.status;
        }

        if (result.verificationSent) {
            return OnboardingStatus.VERIFY_EMAIL;
        }
        if (result.loginRequired) {
            //Navigate the user to the login page - they will proceed to the appropriate place afterwards
            await this.router.navigate(["/login"], {
                queryParams: { emailAddress: this.userDataModel.emailAddress },
            });
            return this.status;
        }
        //Continue with the sign-up wizard
        return OnboardingStatus.CREATE_PASSWORD;
    }

    private async completeSignUp(): Promise<OnboardingStatus> {
        const result = await this.onboardingService.createTrialUser(this.userDataModel);
        if (!result.signupSuccessful) {
            this.errorMessage = result.errorMessage;
            this.recordAnalyticsEvent("create-error");
            return this.status;
        }
        return OnboardingStatus.VERIFY_EMAIL;
    }

    private recordAnalyticsEvent(category: string) {
        this.analyticsService.recordEvent("onboarding", category);
    }

    async resendEmail() {
        try {
            this.showLoader = true;
            this.verifyEmailMessage = null;
            const signupResult = await this.authService.checkSignUpStatus(this.userDataModel.emailAddress);
            if (signupResult.loginRequired) {
                await this.router.navigateByUrl("/register/email-success");
            } else if (signupResult.success && signupResult.verificationSent) {
                this.verifyEmailMessage = {
                    message: "Verification email sent",
                    error: false,
                };
            } else if (signupResult.errorMessage) {
                this.verifyEmailMessage = {
                    message: signupResult.errorMessage,
                    error: true,
                };
            }
            this.showLoader = false;
        } catch (error) {
            this.verifyEmailMessage = {
                message: "Sorry, something went wrong",
                error: true,
            };
            this.showLoader = false;
        }
    }
}
