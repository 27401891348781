import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from "@angular/core";
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { IWorkflowDesignType, SortOption } from "@visoryplatform/threads";
import { Subscription } from "rxjs";
import { UniqueThreadType, WorkflowConfigurationOptions } from "../../types/UniqueThreadType";
import { SelectServiceControl } from "../../types/SelectDesignType";
import { ThreadsHelperService } from "../../../../services/threads-helper.service";

@Component({
    selector: "select-service",
    templateUrl: "./select-service.component.html",
    styleUrls: ["./select-service.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: SelectServiceComponent,
        },
    ],
})
export class SelectServiceComponent implements ControlValueAccessor, OnDestroy, OnChanges {
    @Input() workflowDesignTypes?: IWorkflowDesignType[];
    @Input() showWorkflowConfigurationSelectors = true;

    public sortOption = SortOption;

    form = new FormGroup({
        threadType: new FormControl<UniqueThreadType>(null),
        configuration: new FormControl<WorkflowConfigurationOptions>(null),
    });
    WorkflowConfigurationOptions = WorkflowConfigurationOptions;

    onChange?: (obj: Partial<SelectServiceControl>) => void;
    onTouch?: () => void;

    controlSub?: Subscription;
    threadTypes: UniqueThreadType[];

    constructor(private threadHelperService: ThreadsHelperService) {
        this.controlSub = this.form.valueChanges.subscribe((value) => {
            const mappedValue = { ...value, threadType: value.threadType?.threadType };
            this.onChange?.(mappedValue);
            this.onTouch?.();
        });
    }

    writeValue(obj: SelectServiceControl): void {
        const threadType = this.threadTypes?.find((type) => type.threadType === obj.threadType);

        this.form.patchValue({ ...obj, threadType });
    }

    registerOnChange(fn: (obj: SelectServiceControl) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { workflowDesignTypes } = changes;

        if (workflowDesignTypes && this.workflowDesignTypes) {
            this.threadTypes = this.threadHelperService.getUniqueTypes(this.workflowDesignTypes);
        }
    }

    ngOnDestroy(): void {
        this.controlSub?.unsubscribe();
    }
}
