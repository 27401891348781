<div
    *ngIf="{ globalRole: globalRole$ | async } as context"
    [formGroup]="form"
    class="filters"
>
    <div class="filters-field">
        <v-select
            [options]="services$ | async"
            [optionContent]="serviceOptionContent"
            [tuiTextfieldCleaner]="false"
            [formControl]="form.controls.type"
        >
        </v-select>

        <ng-template
            #serviceOptionContent
            let-option
        >
            {{
                option.value === ALL_OPTION.value ? option.value + " " + servicesPrefix : (option.value | sentencecase)
            }}
        </ng-template>
    </div>
    <div class="filters-field">
        <v-select
            [options]="accounts$ | async"
            [optionContent]="accountOptionContent"
            [tuiTextfieldCleaner]="false"
            [formControl]="form.controls.account"
        >
        </v-select>

        <ng-template
            #accountOptionContent
            let-option
        >
            {{ option.value === ALL_OPTION.value ? option.value + " " + accountsPrefix : option.value }}
        </ng-template>
    </div>

    <div
        class="filters-field"
        *ngIf="globalRole$ | async | permissions : 'ViewWorkflowFilter' | async"
    >
        <v-select
            [options]="workflows$ | async"
            [optionContent]="workflowOptionContent"
            [tuiTextfieldCleaner]="false"
            [formControl]="form.controls.workflow"
        >
        </v-select>

        <ng-template
            #workflowOptionContent
            let-option
        >
            {{
                option.value === ALL_OPTION.value ? option.value + " " + workflowsPrefix : (option.value | sentencecase)
            }}
        </ng-template>
    </div>

    <div class="filters-field">
        <v-select
            [options]="statusOptions"
            [optionContent]="statusOptionContent"
            [tuiTextfieldCleaner]="false"
            [formControl]="form.controls.status"
        >
        </v-select>

        <ng-template
            #statusOptionContent
            let-option
        >
            {{ option.value | sentencecase }}
        </ng-template>
    </div>

    <div class="filters-field">
        <v-select
            [options]="assignees$ | async"
            [optionContent]="assigneesOptionContent"
            [tuiTextfieldCleaner]="false"
            [formControl]="form.controls.assignees"
        >
        </v-select>

        <ng-template
            #assigneesOptionContent
            let-option
        >
            {{ option.value === ALL_OPTION.value ? option.value + " " + assigneesPrefix : option.value }}
        </ng-template>
    </div>

    <div class="filters-field filters-field--search">
        <search
            [analyticsClickEvent]="gaEvents.WORKFLOW_LISTING_SEARCH"
            formControlName="search"
            placeholder="Search workflows"
            tooltip="Search by account, title, status, type, participant or assignee."
        ></search>
    </div>
</div>
