import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { IOnboardingStep } from "../onboarding-completion/IOnboardingStep";
import { EnvironmentSpecificConfig } from "../../../../../../projects/portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";

@Component({
    selector: "app-industry-type",
    templateUrl: "./industry-type.component.html",
    styleUrls: ["./industry-type.component.scss"],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: "fx-onboarding-modal-template" },
})
export class IndustryTypeComponent implements IOnboardingStep {
    @Output() modelValidated: EventEmitter<boolean> = new EventEmitter();
    @Output() modelUpdated: EventEmitter<any> = new EventEmitter();
    @Output() progressState: EventEmitter<any> = new EventEmitter();

    selectedIndustryTypes: string[] = [];
    isValid: boolean;
    filter = "";

    readonly appName: string;
    readonly theme;

    constructor(@Inject(ENVIRONMENT) environment: EnvironmentSpecificConfig) {
        this.appName = environment.appName;
        this.theme = environment.theme;
    }

    updateParentModel(industryTypes: string[]) {
        this.modelUpdated.emit({
            industryTypes,
        });
    }

    updateParentValidation(isValid: boolean) {
        this.isValid = isValid;
        this.modelValidated.emit(isValid);
    }

    updateModel(data: any) {
        this.selectedIndustryTypes = data.industryTypes || [];
    }
}
