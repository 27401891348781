import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatSortModule } from "@angular/material/sort";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { FxUiModule } from "@visoryplatform/fx-ui";
import { SharedModule } from "../shared";
import { FxTableComponent } from "./components/fx-table.component";
import { FxPaginatorComponent } from "./components/fx-paginator/fx-paginator.component";
import { FxColumnDirective } from "./directives/fx-column.directive";
import { FxRowChildDirective } from "./directives/fx-row-child.directive";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { TuiHintModule } from "@taiga-ui/core";

//TODO: move this to FX UI once stable
const exportableDeclarations = [FxTableComponent, FxColumnDirective, FxRowChildDirective, FxPaginatorComponent];

@NgModule({
    declarations: [...exportableDeclarations],
    exports: [...exportableDeclarations],
    imports: [
        CommonModule,
        MatTableModule,
        MatSortModule,
        MatSelectModule,
        FxUiModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
        MatButtonModule,
        TuiHintModule,
    ],
})
export class FxTableModule {}
