import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { IInvitation } from "@visoryplatform/calendar-types";
import { Observable } from "rxjs";
import { DateTime } from "luxon";
import { map } from "rxjs/operators";
import { ICalendarParticipant, IThreadCardsState, IUpcomingMeeting, Role } from "@visoryplatform/threads";
import { CalendarInstance, CalendarState, MeetingStatus } from "../calendar-state.type";
import {
    environmentCommon,
    EnvironmentSpecificConfig,
} from "../../../portal-modules/src/lib/environment/environment.common";
import { ENVIRONMENT } from "../../../../src/app/injection-token";
import { CardStateResponse } from "projects/portal-modules/src/lib/threads-ui/interfaces/CardStateResponse";

export interface ISlot {
    start: string;
    end: string;
}

export interface ICalendarAvailability {
    availabilityView: string;
    slots: ISlot[];
}

@Injectable()
export class CalendarService {
    readonly BASE_URL = this.environment.calendarEndpoints.base;
    readonly BASE_PUBLIC_URL = this.environment.publicEndpoints.base;
    readonly CALENDAR_QUERY = environmentCommon.calendarEndpoints;
    readonly THREAD_PATHS = environmentCommon.threadsEndpoints;
    readonly DEFAULT_TIMEZONE = environmentCommon.defaultTimezone;

    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig) {}

    createSlotPreviews(slots: ISlot[]) {
        const availableDates = new Set(slots.map((val) => DateTime.fromISO(val.start).toLocaleString()));
        const sortedDates = [...(availableDates as any)]
            .sort((a, b) => new Date(a.value).valueOf() - new Date(b.value).valueOf())
            .reverse();
        const slotPreviews = [];

        for (let i = 0; i < 3; i++) {
            if (sortedDates[i]) {
                slotPreviews.push({
                    title: this.createFriendlyDate(sortedDates[i]),
                    slots: slots.filter((val) => DateTime.fromISO(val.start).toLocaleString() === sortedDates[i]),
                });
            }
        }

        return slotPreviews;
    }
    getUpcomingMeetings(): Observable<IUpcomingMeeting[]> {
        const url = `${this.BASE_URL}/${this.CALENDAR_QUERY.upcomingMeetings}`;
        return this.http.get<IUpcomingMeeting[]>(url);
    }
    createFriendlyDate(date: string) {
        const dateArray = date.split("/");
        const day = parseInt(dateArray[0], 10);
        const month = parseInt(dateArray[1], 10);
        const year = parseInt(dateArray[2], 10);
        const dateObject = DateTime.local(year, month, day);
        return `${dateObject.get("weekdayLong")} ${day} ${dateObject.get("monthShort")}`;
    }
    getClientInvitation(invitationId: string): Observable<IInvitation> {
        const url = `${this.BASE_URL}/${this.CALENDAR_QUERY.getInvitation}/${invitationId}`;
        return this.http.get<IInvitation>(url);
    }
    getCalendarCardsState(): Observable<IThreadCardsState<CardStateResponse<CalendarState>>[]> {
        const { base } = this.environment.commonEndpoints;
        const { cards } = environmentCommon.threadsEndpoints;
        const { calendar } = environmentCommon.cardsEndpoints;
        const url = `${base}${calendar}${cards}`;
        return this.http.get<IThreadCardsState<CardStateResponse<CalendarState>>[]>(url);
    }

    setAppointment(invitationId: string, start: string, end: string): Observable<void> {
        const url = `${this.BASE_URL}/${this.CALENDAR_QUERY.setAppointment}/${invitationId}`;
        const targetTimezone = this.getTimeZoneName(start);
        return this.http.put<any>(url, { start, end, targetTimezone });
    }

    updateAppointmentAttendees(
        threadId: string,
        cardId: string,
        invitationId: string,
        staff: ICalendarParticipant[],
        invitees: ICalendarParticipant[],
    ): Observable<void> {
        const { cards, threads } = this.THREAD_PATHS;
        const { attendees } = this.CALENDAR_QUERY;
        const url = `${this.BASE_URL}${threads}/${threadId}${cards}/${cardId}/${invitationId}/${attendees}`;
        return this.http.put<void>(url, { staff, invitees });
    }

    cancelAppointment(threadId: string, cardId: string, invitationId: string, attendeeId: string): Observable<void> {
        const url = `${this.BASE_URL}/${this.CALENDAR_QUERY.cancelAppointment}`;
        return this.http.put<any>(url, { invitationId, attendeeId, threadId, cardId });
    }

    cancelInstance(invitationId: string, instance: CalendarInstance): Observable<void> {
        const url = `${this.BASE_URL}/${this.CALENDAR_QUERY.cancelInstance}/${invitationId}`;
        return this.http.post<any>(url, { instance });
    }

    updateInstance(invitationId: string, instance: CalendarInstance): Observable<void> {
        const url = `${this.BASE_URL}/${this.CALENDAR_QUERY.updateInstance}/${invitationId}`;
        const targetTimezone = this.getTimeZoneName();
        return this.http.put<any>(url, { instance, targetTimezone });
    }

    checkAvailability(invitationId: string, date: string, endDate?: string): Observable<ICalendarAvailability> {
        const { start, oneMonthFromStart, targetTimezone } = this.getAvailabilityDetails(date);
        const url = `${this.BASE_URL}/${this.CALENDAR_QUERY.checkAvailability}/${invitationId}`;
        return this.http
            .post<any>(url, { start, end: endDate || oneMonthFromStart, targetTimezone })
            .pipe(map((response) => response.data));
    }

    checkUserAvailability(
        userIds: string[],
        startDate: string,
        duration: number,
        threadId: string,
    ): Observable<ICalendarAvailability> {
        const { start, oneMonthFromStart, targetTimezone } = this.getAvailabilityDetails(startDate);
        const url = `${this.BASE_URL}/${this.CALENDAR_QUERY.checkAvailability}`;
        return this.http.post<any>(url, {
            start,
            end: oneMonthFromStart,
            targetTimezone,
            userIds,
            duration,
            threadId,
        });
    }

    checkPublicStaffAvailability(
        staffId: string,
        startDate: string,
        duration: number,
    ): Observable<ICalendarAvailability> {
        const { start, oneMonthFromStart, targetTimezone } = this.getAvailabilityDetails(startDate);
        const url = `${this.BASE_PUBLIC_URL}/${this.CALENDAR_QUERY.publicCheckStaffAvailability}`;
        return this.http.post<any>(url, {
            start,
            end: oneMonthFromStart,
            targetTimezone,
            staffId,
            duration,
        });
    }

    getTimezoneSubtitle(): string {
        const now = DateTime.local();
        const zoneName = this.getTimeZoneName();
        const offset = now.zone?.formatOffset(now.valueOf(), "short");

        return `(UTC${offset}) ${zoneName}`;
    }

    findNextInstance(instances: CalendarInstance[]): CalendarInstance {
        const now = Date.now();

        if (instances) {
            const nextMeeting = instances.find((instance) => new Date(instance.end).getTime() > now);
            return nextMeeting || instances[instances.length - 1];
        }

        return null;
    }

    getMeetingStatus(state: CalendarState): MeetingStatus {
        if (!state?.cancelled && !state?.scheduled) {
            return MeetingStatus.Request;
        }

        const nextInstance = this.findNextInstance(state.instances);
        const nextTime = nextInstance ? new Date(nextInstance.end).getTime() : 0;

        // TODO: Show meeting has ended after last scheduled instance
        if (state?.cancelled || Date.now() > nextTime) {
            return MeetingStatus.Ended;
        }
        if (state?.scheduled) {
            return MeetingStatus.Confirmed;
        }
        return MeetingStatus.Unknown;
    }

    filterSlotsByRole(userRole: Role, slots: ISlot[]): ISlot[] {
        if (!slots.length) {
            return slots;
        }

        const slotsWithoutToday = slots.filter((slot: ISlot) => !this.isToday(slot.start));

        switch (userRole) {
            case Role.Client:
                return slotsWithoutToday;
            default:
                return slots;
        }
    }

    /**
     * in some rear cases Luxon can not read timezone from Intl, in case like this we are trying to read it from JSDate as a fallback
     * if Intl and JSDate timezone retrieve fails we are returning default timezone Austarlia/Melbourne
     */
    getTimeZoneName(date?: string | Date): string {
        const intlTimezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const jsDate = date ?? new Date();
        const luxonDateTimezoneName = DateTime.fromJSDate(new Date(jsDate)).zone?.name;

        return intlTimezoneName || luxonDateTimezoneName || this.DEFAULT_TIMEZONE;
    }

    private getAvailabilityDetails(startDate: string): {
        start: string;
        oneMonthFromStart: string;
        targetTimezone: string;
    } {
        const startDateTime = DateTime.fromISO(startDate);
        const start = startDateTime.startOf("month").toISO();
        const oneMonthFromStart = DateTime.fromISO(startDate).plus({ months: 1 }).toISO();
        const targetTimezone = this.getTimeZoneName(startDate);
        return {
            start,
            oneMonthFromStart,
            targetTimezone,
        };
    }

    private isToday(date: string): boolean {
        const formattedDate = new Date(date).toDateString();
        const today = new Date().toDateString();
        return formattedDate === today;
    }
}
