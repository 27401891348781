import { Component, forwardRef, Inject, OnDestroy, OnInit } from "@angular/core";
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { combineLatest, Observable, Subject, Subscription } from "rxjs";
import { FilterOption, ITimelineFilters } from "../../interfaces/timeline-filters";
import { IParticipant, Role, ThreadStatus } from "@visoryplatform/threads";
import { GA_EVENTS } from "../../../analytics";
import { AuthService } from "../../../findex-auth";
import { map, shareReplay, skip, switchMap, take } from "rxjs/operators";
import { ALL_OPTION } from "../../constants/option-constants";
import { ThreadFilterService } from "../../../threads-ui/services/thread-filter.service";
import { ParticipantCache } from "../../../threads-ui/services/participant-cache.service";
import { ThreadsService } from "../../../threads-ui/services/threads.service";
import { EnvironmentSpecificConfig } from "../../../environment/environment.common";
import { ENVIRONMENT } from "src/app/injection-token";
import { ActivatedRoute } from "@angular/router";
import { Loader } from "../../../shared/services/loader";

type AccountFilterResponse = { label: string; id: string };
type WorkflowLabelFilterResponse = { label: string };
type WorkflowAssigneesFilterResponse = { assignees: string };
type ThreadTypeFilterResponse = { type: string };

@Component({
    selector: "timeline-filters",
    templateUrl: "./timeline-filters.component.html",
    styleUrls: ["./timeline-filters.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimelineFiltersComponent),
            multi: true,
        },
    ],
})
export class TimelineFiltersComponent implements OnInit, OnDestroy, ControlValueAccessor {
    statusOptions: FilterOption[];
    accounts$: Observable<FilterOption[]>;
    assignees$: Observable<FilterOption[]>;
    workflows$: Observable<FilterOption[]>;
    services$: Observable<FilterOption[]>;

    readonly role = Role;
    readonly gaEvents = GA_EVENTS;
    readonly statusesPrefixx = "statuses";
    readonly workflowsPrefix = "workflows";
    readonly servicesPrefix = "services";
    readonly assigneesPrefix = "assignees";
    readonly accountsPrefix = "accounts";

    searchQuerySource$ = new Subject<string>();
    searchQuery$ = this.searchQuerySource$.asObservable();
    filtersSubscription: Subscription;
    allOptionsSubscription: Subscription;

    form = new FormGroup({
        type: new FormControl<FilterOption>(null),
        status: new FormControl<FilterOption>(null),
        account: new FormControl<FilterOption>(null),
        search: new FormControl<string>(""),
        assignees: new FormControl<FilterOption>(null),
        workflow: new FormControl<FilterOption>(null),
    });

    globalRole$: Observable<Role>;

    onChange?: (value: unknown) => void;
    onTouch?: () => void;

    protected readonly ALL_OPTION = ALL_OPTION;

    constructor(
        private authService: AuthService,
        private threadFilterService: ThreadFilterService,
        private participantCache: ParticipantCache,
        private threadsService: ThreadsService,
        private route: ActivatedRoute,
        public loader: Loader,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
    ) {}

    ngOnInit(): void {
        this.globalRole$ = this.authService.getValidUser().pipe(
            map((user) => user.globalRole),
            take(1),
            shareReplay(1),
        );

        this.statusOptions = this.getStatusOptions();
        this.accounts$ = this.getAccountsFilters().pipe(shareReplay(1));
        this.assignees$ = this.getAssigneesFilters().pipe(shareReplay(1));
        this.workflows$ = this.getWorkflowsFilters().pipe(shareReplay(1));
        this.services$ = this.getServicesFilters().pipe(shareReplay(1));

        const allOptions = [this.accounts$, this.assignees$, this.workflows$, this.services$];

        this.initFormOnChanges();

        this.allOptionsSubscription = this.loader
            .wrap(combineLatest(allOptions))
            .pipe(take(1))
            .subscribe(([accounts, assignees, workflows, services]) => {
                const filters = this.getFiltersValues(services, accounts, assignees, workflows);
                this.form.setValue(filters);
            });
    }

    addAllOption(options: FilterOption[]): FilterOption[] {
        return [ALL_OPTION, ...options];
    }

    ngOnDestroy(): void {
        this.filtersSubscription?.unsubscribe();
        this.allOptionsSubscription?.unsubscribe();
    }

    writeValue(value: ITimelineFilters): void {
        this.form.setValue(value, { emitEvent: false });
    }

    registerOnChange(fn: (value: ITimelineFilters) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    private initFormOnChanges(): void {
        this.filtersSubscription = this.form.valueChanges.pipe(skip(1)).subscribe((values) => {
            this.onChange(values);
        });
    }

    private getFiltersValues(
        typeOptions: FilterOption[],
        accountOptions: FilterOption[],
        assigneeOptions: FilterOption[],
        workflowOptions: FilterOption[],
    ): ITimelineFilters {
        const { status: urlStatus, type, account, assignees, workflow, search } = this.route.snapshot.queryParams;
        const statusKey = urlStatus ? urlStatus : ThreadStatus.active;

        const status = {
            key: statusKey,
            value: this.getStatusValue(statusKey, this.statusOptions),
        };

        const typeOption = this.getFilterOptionFromOptions(type, typeOptions);
        const accountOption = this.getFilterOptionFromOptions(account, accountOptions);
        const assigneesOption = this.getFilterOptionFromOptions(assignees, assigneeOptions);
        const workflowOption = this.getFilterOptionFromOptions(workflow, workflowOptions);

        return {
            status,
            type: typeOption,
            account: accountOption,
            assignees: assigneesOption,
            workflow: workflowOption,
            search: search || "",
        };
    }

    private getFilterOptionFromOptions(key: string, filterOptions: FilterOption[]): FilterOption {
        const value = filterOptions.find((option) => option.key === key)?.value || ALL_OPTION.value;
        return { key: key || ALL_OPTION.key, value };
    }

    private getStatusValue(key: string, statusOptions: Array<FilterOption>): string {
        return statusOptions.find((option) => option.key === key)?.value || ThreadStatus.active;
    }

    private getAssigneesFilters(): Observable<FilterOption[]> {
        return this.globalRole$.pipe(
            map((role) => this.threadFilterService.getAssigneeFilterSource(role)),
            switchMap((source) => this.threadFilterService.getFilteredThreads<WorkflowAssigneesFilterResponse>(source)),
            switchMap((assignees) => this.mapAssigneesToParticipants(assignees)),
            map((participants) => participants.sort((a, b) => a?.profile?.name?.localeCompare(b?.profile?.name))),
            map((participants) => participants.map((participant) => this.mapParticipantToFilterOption(participant))),
            map((participants) => this.addAllOption(participants)),
        );
    }

    private mapAssigneesToParticipants(assignees: WorkflowAssigneesFilterResponse[]): Observable<IParticipant[]> {
        const uniqueAssigneeIds = new Set(assignees.map((assignee) => assignee.assignees));
        const assigneesIds = Array.from(uniqueAssigneeIds);
        return this.participantCache.getParticipants(assigneesIds);
    }

    private mapParticipantToFilterOption(participant): FilterOption {
        return { key: participant.id, value: participant.profile.name };
    }

    private getAccountsFilters(): Observable<FilterOption[]> {
        return this.threadFilterService.getFilteredThreads<AccountFilterResponse>("account").pipe(
            map((accounts) => accounts.sort((a, b) => a.label.localeCompare(b.label))),
            map((accounts) => accounts.map((account) => ({ key: account.id, value: account.label }))),
            map((accounts) => this.addAllOption(accounts)),
        );
    }

    private getWorkflowsFilters(): Observable<FilterOption[]> {
        return this.threadFilterService.getFilteredThreads<WorkflowLabelFilterResponse>("workflow").pipe(
            map((accounts) => accounts.sort((a, b) => a.label.localeCompare(b.label))),
            map((workflows) => workflows.map((workflow) => ({ key: workflow.label, value: workflow.label }))),
            map((workflows) => this.addAllOption(workflows)),
        );
    }

    private getServicesFilters(): Observable<FilterOption[]> {
        const filteredServices$ = this.threadFilterService.getFilteredThreads<ThreadTypeFilterResponse>("type");
        const serviceTypes$ = this.threadsService.getThreadTypes();
        return combineLatest([filteredServices$, serviceTypes$]).pipe(
            map(([filteredServices, serviceTypes]) =>
                filteredServices.map((service) => this.mapServiceToFilterOption(serviceTypes, service)),
            ),
            map((workflows) => workflows.filter((workflow) => !!workflow.value)),
            map((workflows) => workflows.sort((a, b) => a.value.localeCompare(b.value))),
            map((workflows) => this.addAllOption(workflows)),
        );
    }

    private mapServiceToFilterOption(
        serviceTypes: Record<string, string>,
        service: ThreadTypeFilterResponse,
    ): FilterOption {
        const [, serviceType] = Object.entries(serviceTypes).find(([key]) => key === service.type) ?? [];
        return { key: service.type, value: serviceType };
    }

    private getStatusOptions(): FilterOption[] {
        const optionsFromEnv = Object.entries(this.environment.featureFlags.threadListFilterStatus)
            .map(([key, value]) => ({ key, value }))
            .sort((a, b) => a.value.localeCompare(b.value));
        const allOption = { ...ALL_OPTION, value: "All statuses" };
        return [allOption, ...optionsFromEnv];
    }
}
