import { NgModule } from "@angular/core";
import { ROUTES, RouterModule } from "@angular/router";
import { THREAD_LIBRARY } from "src/app/injection-token";
import { ILibrary, RouteExtension, RouteHelper } from "../plugins";
import { ThreadsListRouteComponent } from "./components/threads-list-route/threads-list-route.component";
import { TimelineListComponent } from "./components/timeline-list/timeline-list.component";

@NgModule({
    imports: [RouterModule],
    exports: [RouterModule],
    providers: [
        {
            provide: ROUTES,
            useFactory: (library: ILibrary<RouteExtension>) => {
                const dynamicRoutes = RouteHelper.getRoutes(library);
                return [
                    { path: "", component: TimelineListComponent },
                    {
                        path: ":threadId",
                        component: ThreadsListRouteComponent,
                        children: [
                            {
                                path: "",
                                redirectTo: "activity",
                                pathMatch: "full",
                            },
                            {
                                path: "activity",
                                children: [], // route is superficial and does not load component
                            },
                            {
                                path: "cards/:cardId",
                                redirectTo: "activity/cards/:cardId",
                            },
                            {
                                path: "activity/cards/:cardId",
                                children: [], // route is superficial and does not load component
                            },
                            ...dynamicRoutes,
                        ],
                    },
                ];
            },
            deps: [THREAD_LIBRARY],
            multi: true,
        },
    ],
})
export class WorkflowRoutesModule {}
