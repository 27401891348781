<div
    *ngIf="showMenu"
    class="fx-component-profile-overlay"
    (click)="toggleMenu()"
></div>
<div class="fx-component-profile">
    <!-- TODO: should we make this overlay into some sort of reusable component -->
    <div
        class="fx-component-profile-trigger"
        (click)="toggleMenu()"
    >
        <i
            class="las la-user la-2x"
            aria-hidden="true"
        ></i>
    </div>
    <div
        *ngIf="showMenu"
        class="fx-component-profile-dropdown"
    >
        <div
            class="fx-component-profile-dropdown-username"
            href="#"
        >
            {{ userName }}
        </div>
        <ng-content></ng-content>
    </div>
</div>
