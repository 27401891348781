<div class="upload-cancel-modal">
    <p>The changes you have made may be lost if you navigate away from this page.</p>
    <p>Are you sure you want to leave this page?</p>
    <div class="upload-cancel-modal-buttons">
        <button
            class="fx-btn fx-btn--primary"
            type="button"
            [mat-dialog-close]="true"
        >
            Yes
        </button>
        <button
            class="fx-btn fx-btn--secondary"
            type="button"
            [mat-dialog-close]="false"
        >
            No
        </button>
    </div>
</div>
