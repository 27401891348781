import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { CreateInvitationComponent } from "./components/create-invitation/create-invitation.component";
import { CalendarCardComponent } from "./components/calendar-card/calendar-card.component";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CreateInvitationCloseModalComponent } from "./components/calendar-meeting-request/close-modal/create-invitation-close-modal.component";
import { CalendarReviewMeetingComponent } from "./components/calendar-review-meeting/calendar-review-meeting.component";
import { QuillModule } from "ngx-quill";
import { CalendarChooseTimeComponent } from "./components/calendar-choose-time/calendar-choose-time.component";
import { CalendarInstanceModalComponent } from "./components/calendar-instance-modal/calendar-instance-modal.component";
import { CalendarInstanceComponent } from "./components/calendar-instance-modal/calendar-instance/calendar-instance.component";
import { CalendarModalComponent } from "./components/calendar-modal/calendar-modal.component";
import { CalendarDetailsModalComponent } from "./components/calendar-details-modal/calendar-details-modal.component";
import { CalendarEmptyServicesModalComponent } from "./components/calendar-empty-services-modal/calendar-empty-services-modal.component";
import { CalendarMeetingRequestComponent } from "./components/calendar-meeting-request/calendar-meeting-request.component";
import { CalendarBookMeetingComponent } from "./components/calendar-book-meeting/calendar-book-meeting.component";
import { CalendarMeetingStartedBannerComponent } from "./components/calendar-meeting-started-banner/calendar-meeting-started-banner.component";
import { AnalyticsModule } from "projects/portal-modules/src/lib/analytics";
import { AuthInterceptor } from "projects/portal-modules/src/lib/findex-auth";
import { FindexUiModule } from "projects/portal-modules/src/lib/findex-ui";
import { SharedModule } from "projects/portal-modules/src/lib/shared";
import { ThreadsUiModule } from "projects/portal-modules/src/lib/threads-ui";
import { VideoChatModule } from "../video-chat/video-chat.module";
import { PLUGIN_FACTORY } from "projects/portal-modules/src/lib/plugins";
import { CalendarPlugin } from "./calendar.plugin";
import { CalendarService } from "./services/calendar.service";
import { CalendarCardService } from "./services/calendar-card.service";
import { ExtensionSharedModule } from "projects/portal-modules/src/lib/extension-shared/extension-shared.module";
import { RouterModule } from "@angular/router";
import { ThreadParticipantsModule } from "projects/portal-modules/src/lib/threads-ui/modules/thread-participants";

const declarations = [
    CalendarBookMeetingComponent,
    CalendarMeetingRequestComponent,
    CalendarModalComponent,
    CalendarChooseTimeComponent,
    CreateInvitationComponent,
    CalendarCardComponent,
    CalendarModalComponent,
    CreateInvitationCloseModalComponent,
    CalendarReviewMeetingComponent,
    CalendarDetailsModalComponent,
    CalendarInstanceComponent,
    CalendarInstanceModalComponent,
    CalendarMeetingStartedBannerComponent,
    CalendarEmptyServicesModalComponent,
];

@NgModule({
    imports: [
        CommonModule,
        ThreadsUiModule,
        FindexUiModule,
        MatDialogModule,
        AnalyticsModule,
        SharedModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
        FormsModule,
        QuillModule,
        VideoChatModule,
        ExtensionSharedModule,
        RouterModule,
        ThreadParticipantsModule,
    ],
    exports: [],
    declarations: [...declarations],
    providers: [
        CalendarService,
        CalendarCardService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: PLUGIN_FACTORY, useClass: CalendarPlugin, multi: true },
    ],
})
export class CalendarModule {}
