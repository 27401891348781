<ng-container *ngIf="card$ | async; let card">
    <ng-container *ngIf="thread$ | async as thread">
        <request
            (save)="updateRequestState(card, thread)"
            (close)="close()"
            [loading]="!!loader?.counter"
            [subtitle]="thread.title | titlecase"
            [accountName]="thread.account.label"
            [cancelButtonTitle]="null"
            [completedStatusLabel]="requestStatuses.Completed"
            [disableSave]="!form.valid"
            [saveButtonTitle]="readonly || !form.dirty ? buttonLabel.Close : buttonLabel.SaveClose"
            [analyticsPrefix]="
                actionedPercentage === 100 ? analyticsTags.analyticsEventComplete : analyticsTags.analyticsEventPending
            "
            [card]="card"
            [state]="state"
        >
            <form [formGroup]="form">
                <edit-request-title [form]="form"></edit-request-title>

                <div class="request-common-group">
                    <div class="request-common-label request-common-group-header">Note or message</div>
                    <request-view-reply [cardDescription]="form.controls.cardDescription"></request-view-reply>
                </div>

                <div
                    class="request-common-group"
                    *ngIf="state?.attachments?.data"
                >
                    <div class="request-common-label request-common-group-header">Request attachments</div>
                    <file-input
                        [analyticsPrefix]="analyticsTags.analyticsPrefix"
                        (file)="handleAttachmentAdded($event)"
                    ></file-input>

                    <request-attachments
                        [attachments]="state?.attachments"
                        [vaultId]="state?.vaultId"
                        [canDelete]="true"
                        [analyticsPrefix]="analyticsTags.analyticsPrefix"
                        (deleteAttachment)="handleAttachmentDeleted($event)"
                    ></request-attachments>
                </div>
                <rfi-todos
                    title="Action items for this request"
                    [state]="state"
                    [isEditing]="true"
                    [actionedRequestItems]="actionedRequestItems"
                    [actionedPercentage]="actionedPercentage"
                    [form]="form"
                    [card]="card"
                    [userId]="userId$ | async"
                    [readonly]="readonly"
                    [thread]="thread"
                    [canReopenRequest]="canReopenRequest$ | async"
                    (removeRequestItem)="handleRequestItemRemoved($event.controlIndex, $event.control)"
                    (addRequestItem)="addRequestItem(form, $event)"
                ></rfi-todos>

                <button
                    [disabled]="state?.isCompleted"
                    (click)="addRequestItem(form, state?.isCompleted)"
                    [ngClass]="{ 'request-common-todo-add-item--disabled': !form.controls.requestItems.valid }"
                    class="request-common-todo-add-item"
                >
                    {{ buttonLabel.AddItem }}
                </button>

                <div
                    [ngClass]="
                        actionedPercentage === 100
                            ? 'request-common-complete-all--disabled'
                            : 'request-common-complete-all'
                    "
                >
                    <button
                        [disabled]="state.requestItems.length < form.controls.requestItems.length"
                        (click)="markAllComplete(true, card, state, thread.id)"
                    >
                        {{ buttonLabel.MarkAllAsComplete }}
                    </button>
                </div>
            </form>
        </request>
    </ng-container>
</ng-container>
