import { Component, Inject, OnInit } from "@angular/core";
import { CardResources, THREAD_CARD_RESOURCES } from "projects/portal-modules/src/lib/threads-ui/interfaces/IUiCard";
import { ActorId, CardReply, CardStatus, IThread, IThreadCard, Role } from "@visoryplatform/threads";
import { combineLatest, Observable, Subject } from "rxjs";
import { filter, map, shareReplay, switchMap, take } from "rxjs/operators";
import { AuthService } from "projects/portal-modules/src/lib/findex-auth";
import { ENVIRONMENT } from "src/app/injection-token";
import { EnvironmentSpecificConfig } from "projects/portal-modules/src/lib/environment/environment.common";
import { ThreadCardService } from "projects/portal-modules/src/lib/threads-ui/services/thread-card.service";
import { FeatureFlagService, LaunchDarklyFeatureFlags } from "projects/portal-modules/src/lib/feature-flags";
import { ForwardMessageModalComponent } from "projects/portal-modules/src/lib/threads-ui/components/forward-message/forward-message-modal.component";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { PermissionService } from "projects/portal-modules/src/lib/threads-ui/services/permissions.service";

@Component({
    selector: "message-card",
    templateUrl: "./message-card.component.html",
    styleUrls: ["./message-card.component.scss"],
})
export class MessageCardComponent implements OnInit {
    readonly allowEdit = this.environment.featureFlags.editCardDescription;
    readonly FEATURE_FLAGS = LaunchDarklyFeatureFlags;

    card$: Observable<IThreadCard>;
    cardStatuses = CardStatus;
    edit$ = new Subject<boolean>();
    errorMessage: string;
    loading: boolean;
    message: string;
    replies$: Observable<CardReply[]>;
    thread$: Observable<IThread>;
    userId$: Observable<string>;
    userRole$: Observable<Role>;
    canRemoveCard$: Observable<boolean>;
    canForwardCard$: Observable<boolean>;
    enableContextMenu$: Observable<boolean>;
    actorIds = ActorId;

    private threadId: string;
    private cardId: string;

    constructor(
        @Inject(THREAD_CARD_RESOURCES) private resources: CardResources,
        private authService: AuthService,
        private cardService: ThreadCardService,
        @Inject(ENVIRONMENT) private environment: EnvironmentSpecificConfig,
        private dialog: MatDialog,
        private permissionService: PermissionService,
        private featureFlagService: FeatureFlagService,
    ) {}

    ngOnInit() {
        const { threadId, cardId, card$, thread$, replies$ } = this.resources;
        const user$ = this.authService.getUser().pipe(filter((user) => !!user));

        this.threadId = threadId;
        this.cardId = cardId;
        this.thread$ = thread$;
        this.card$ = card$.pipe(shareReplay(1));
        this.replies$ = replies$;
        this.userId$ = user$.pipe(
            map((user) => user.id),
            shareReplay(1),
        );
        this.userRole$ = user$.pipe(
            map((user) => user.globalRole),
            shareReplay(1),
        );

        this.canRemoveCard$ = this.userRole$.pipe(
            switchMap((role) => this.permissionService.checkPermissions(role, "RemoveCard")),
            switchMap((permission) => this.canRemoveCard(permission)),
            shareReplay(1),
        );

        this.canForwardCard$ = this.userRole$.pipe(
            switchMap((role) => this.canForwardCard(role)),
            shareReplay(1),
        );

        this.enableContextMenu$ = combineLatest([this.canRemoveCard$, this.canForwardCard$, this.card$]).pipe(
            map(([canRemoveCard, canForwardCard, card]) => {
                const systemCard = card.createdBy === ActorId.System;
                return (canRemoveCard || canForwardCard) && !systemCard;
            }),
        );
    }

    editMessage() {
        this.edit$.next(true);
    }

    async forwardMessage(): Promise<void> {
        const cardPromise = this.card$.pipe(take(1)).toPromise();
        const threadPromise = await this.thread$.pipe(take(1)).toPromise();
        const [card, thread] = await Promise.all([cardPromise, threadPromise]);
        const config = {
            disableClose: false,
            backdropClass: "modal-backdrop",
            panelClass: ["threads-sidebar", "mat-dialog-no-styling"],
            closeOnNavigation: true,
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
            height: "100vh",
            data: {
                thread,
                card,
            },
        };

        this.dialog
            .open(ForwardMessageModalComponent, config)
            .afterClosed()
            .pipe(filter((result) => !!result))
            .subscribe();
    }

    async removeMessage() {
        this.errorMessage = null;
        this.loading = true;
        try {
            await this.cardService.removeCard(this.threadId, this.cardId).toPromise();

            this.loading = false;
        } catch {
            this.loading = false;
            this.errorMessage = "Sorry, something went wrong";
        }
    }

    async save(updatedMessage: string) {
        this.errorMessage = null;
        this.loading = true;
        try {
            await this.cardService
                .updateCardDescription(this.threadId, this.cardId, updatedMessage, CardStatus.Edited)
                .toPromise();

            this.loading = false;
        } catch {
            this.loading = false;
            this.errorMessage = "Sorry, something went wrong";
        }
    }

    private canRemoveCard(removeCardPermission: boolean): Observable<boolean> {
        return combineLatest([this.card$, this.userId$]).pipe(
            map(([card, userId]) => card.createdBy === userId || removeCardPermission),
        );
    }

    private canForwardCard(role: Role): Observable<boolean> {
        const forwardFlag = LaunchDarklyFeatureFlags.EnableForwardingMessageOrReply;
        const canForwardCardFlag$ = this.featureFlagService.getFlag(forwardFlag);
        const permission$ = this.permissionService.checkPermissions(role, "ForwardCard");

        return combineLatest([canForwardCardFlag$, permission$]).pipe(map(([flag, permission]) => flag && permission));
    }
}
