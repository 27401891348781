import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SearchComponent } from "./components/search/search.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AnalyticsModule } from "../analytics";
import { PortalUiModule } from "@visoryplatform/portal-ui";

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, AnalyticsModule, PortalUiModule],
    declarations: [SearchComponent],
    exports: [SearchComponent],
    providers: [],
})
export class SearchModule {
    constructor() {}
}
